<div id="site_wrapper">
    <div class="contenedor-principal">    
        <div class="row pt-3">
            <div class="col-sm-12 btn-regresar-2">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="back-cuestionario">
            <h2>{{objPreview.Nombre}}</h2>
            <div *ngIf="opcionalGen" class="switch-form-base">
                <label class="switch">
                    <input [(ngModel)]="aplicaGen" type="checkbox" (click)="noAplicaGen()">
                    <span class="slider round"></span>
                </label>
                &nbsp;
                <label class="lblSwitch">No Aplica</label>
            </div>
            <p>{{objPreview.Descripcion}}</p>
            <button *ngIf="!identity.empresa;" placement="right" ngbTooltip="Periodo determinado para carga de documentación." 
            class="btn-layout btn-med btnTableUp" (click)="agregarItem(true)">Disponibilidad</button>
            <button *ngIf="btnQR;" class="btn-layout btn-med btnTableUp" (click)="preview(true)">Código QR</button>
        </div>
        <div id="SeccionPreguntas">
            <div class="back-cuestionario">
                <ng-container *ngFor="let group of preguntas">
                    <div *ngIf="!group.hidden" class="mt-3 mb-3">
                        <div class="card-header">
                            <p class="mt-3">
                                <label class="react-text" placement="right" [ngbTooltip]="group.Descripcion" [disableTooltip]="group.Descripcion == ' '">{{group.Nombre}}</label> 
                                <a *ngIf="group.Mandatorio != 'Deseable';" style="color: red;">* Mandatorio {{group.Mandatorio}}</a>
                            </p>
                            <div *ngIf="group.Historial != null;">
                                <button class="btn-info btn-med btn mb-3" style="border-radius:50px;" (click)="historial(true, group.ReactivoItemEmpresaID)">HISTORIAL</button>
                            </div>
                            <div *ngIf="group.Opcional && !opcionalGen;" class="switch-form-base">
                                <div *ngIf="(group.ItemID != 21 && group.ItemID != 11) || group.ReactivoID == 134; else elseStatement">
                                    <label class="switch">
                                        <input [disabled]="group.Disable || (group.accessC.length == 0)" [(ngModel)]="group.NoAplica" type="checkbox" id="swtNA-{{group.ReactivoItemEmpresaID}}" name="swtNA-{{group.ReactivoItemEmpresaID}}">
                                        <span class="slider round"></span>
                                    </label>
                                    &nbsp;
                                    <label class="lblSwitch">No Aplica</label>
                                </div>
                                <ng-template #elseStatement>
                                    <input [disabled]="(group.Disable || (group.accessC.length == 0)) && group.Aplica != 'false'" (click)="aplica(group.ReactivoItemEmpresaID, false)" type="radio" name="swtNA-{{group.ReactivoItemEmpresaID}}" id="pregunta-si-swtNA-{{group.ReactivoID}}" value="false" [(ngModel)]="group.Aplica" style="cursor: pointer;"><label for="pregunta-si-swtNA-{{group.ReactivoID}}" style="cursor: pointer;">Si &nbsp; &nbsp;</label>
                                    <input [disabled]="(group.Disable || (group.accessC.length == 0)) && group.Aplica != 'true'" (click)="aplica(group.ReactivoItemEmpresaID, true)" type="radio" name="swtNA-{{group.ReactivoItemEmpresaID}}" id="pregunta-no-swtNA-{{group.ReactivoID}}" value="true" [(ngModel)]="group.Aplica" style="cursor: pointer;"><label for="pregunta-no-swtNA-{{group.ReactivoID}}" style="cursor: pointer;">No</label>
                                </ng-template>
                            </div>
                        </div>
                        <div class="card-body">
                            <div *ngIf="!identity.empresa && group.TipoFK != 7;">
                                <div *ngIf="group.Status > 0 && group.accessW.length > 0 && !hiddenCert.includes(group.ReactivoID);">
                                    <button class="btn-danger btn-med mb-3" (click)="certificar(group.ReactivoItemEmpresaID,3)">RECHAZAR</button>
                                    <button class="btn-success btn-med mb-3" (click)="certificar(group.ReactivoItemEmpresaID,2)">CERTIFICAR</button>
                                </div>
                                <div *ngIf="group.Status == 2;">
                                    <label class="" style="color: green;">CERTIFICADO</label>
                                </div>
                                <div *ngIf="group.Status == 3;">
                                    <label class="" style="color: red;" *ngIf="group.MotivoRechazo == null || group.MotivoRechazo == ''; else elseRechazo">RECHAZADO</label>
                                    <ng-template #elseRechazo>
                                        <label class="" style="color: red;" *ngIf="group.MotivoRechazo == 'Otro';"
                                            placement="right" [ngbTooltip]="group.ComentarioRechazo">RECHAZADO</label>
                                            <label class="" style="color: red;" *ngIf="group.MotivoRechazo != 'Otro';"
                                                placement="right" [ngbTooltip]="group.MotivoRechazo">RECHAZADO</label>
                                    </ng-template>
                                </div>
                            </div>
                            <div *ngIf="group.Vencimiento && !group.NoAplica;">
                                <label>Fecha de Vencimiento</label>
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="date" class="form-control mb-3" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.VigenciaFIN">
                            </div>
                            <div *ngIf="group.TipoFK == 1 && !group.NoAplica;">
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="text" class="form-control" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.Respuesta">
                            </div>
                            <div *ngIf="group.TipoFK == 2 && !group.NoAplica;">
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="date" class="form-control" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.Respuesta">
                            </div>
                            <div *ngIf="group.TipoFK == 3 && !group.NoAplica;">
                                <button [hidden]="!group.Respuesta || group.Respuesta == '' || !group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3" (click)="descargar(group.ReactivoItemEmpresaID)">DESCARGAR DOCUMENTO</button>
                                <button [hidden]="!group.Respuesta || group.Respuesta == '' || group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3" (click)="verDoc(group.Respuesta)">DESCARGAR DOCUMENTO</button><br>
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="file" name="pregunta-{{group.ReactivoItemEmpresaID}}" accept=".pdf" (change)="readUrl($event, group.ReactivoItemEmpresaID)">
                            </div>
                            <div *ngIf="group.TipoFK == 4 && !group.NoAplica;">
                                <label>Estado</label>
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="text" class="form-control" [(ngModel)]="group.Direccion.Estado">
                                <label>Ciudad/Municipio</label>
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="text" class="form-control" [(ngModel)]="group.Direccion.Municipio">
                                <label>Código Postal</label>
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="number" class="form-control" [(ngModel)]="group.Direccion.CP">
                                <label>Colonia</label>
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="text" class="form-control" [(ngModel)]="group.Direccion.Colonia">
                                <label>Calle</label>
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="text" class="form-control" [(ngModel)]="group.Direccion.Calle">
                                <label>Num.</label>
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="text" class="form-control" [(ngModel)]="group.Direccion.Num">
                            </div>
                            <div *ngIf="group.TipoFK == 5 && !group.NoAplica;">
                                <input [disabled]="(group.Disable || (group.accessC.length == 0)) && group.Aplica != 'si'" type="radio" name="pregunta-{{group.ReactivoItemEmpresaID}}" id="pregunta-si-{{group.ReactivoID}}" value="si" [(ngModel)]="group.Respuesta" style="cursor: pointer;"><label for="pregunta-si-{{group.ReactivoID}}" style="cursor: pointer;">Si &nbsp; &nbsp;</label>
                                <input [disabled]="(group.Disable || (group.accessC.length == 0)) && group.Aplica != 'no'" type="radio" name="pregunta-{{group.ReactivoItemEmpresaID}}" id="pregunta-no-{{group.ReactivoID}}" value="no" [(ngModel)]="group.Respuesta" style="cursor: pointer;"><label for="pregunta-no-{{group.ReactivoID}}" style="cursor: pointer;">No</label>
                            </div>
                            <div *ngIf="group.TipoFK == 6 && !group.NoAplica;">
                                <input [disabled]="group.Disable || (group.accessC.length == 0)" type="email" class="form-control" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.Respuesta">
                            </div>
                            <div *ngIf="group.TipoFK == 8 && !group.NoAplica && group.ReactivoID != 132;">
                                <select [disabled]="group.Disable || (group.accessC.length == 0)" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.Respuesta" class="form-control">
                                    <option *ngFor="let item of group.Catalogos" [value]="item.ID">{{item.Nombre}}</option>
                                </select>
                            </div>
                            <div *ngIf="group.TipoFK == 8 && !group.NoAplica && group.ReactivoID == 132;">
                                <select [disabled]="group.Disable || (group.accessC.length == 0)" name="pregunta-{{group.ReactivoItemEmpresaID}}" [(ngModel)]="group.Respuesta" class="form-control" (change)="hideFedEst(group.ReactivoItemEmpresaID)">
                                    <option *ngFor="let item of group.Catalogos" [value]="item.ID">{{item.Nombre}}</option>
                                </select>
                            </div>
                            <div *ngIf="group.Multiple && !group.NoAplica && !group.Disable;">
                                <button [hidden]="group.Disable || (group.accessC.length == 0)" class="btn-Anadir btn-fluid mb-3 mt-3" (click)="anadirReact(group.ReactivoItemEmpresaID)">AGREGAR <a style="color: red;">(Presionar solo en caso necesario)</a></button>
                            </div>
                            <a [hidden]="group.TipoFK == 7 || group.ReactivoItemEmpresaID < 0" class="lbl-noActive btn" (click)="ticket(true,group.ReactivoItemEmpresaID)">+Ticket Ayuda</a>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div *ngIf="identity.empresa || (!identity.empresa && (objPreview.accessW || objPreview.accessC));" class="row col-12">
                <button class="btn-layout btn-med btn-guardar mb-4" (click)="guardar(0)">GUARDAR</button>
            </div>
        </div>
        
    </div>
</div>

<div class="modal fade" id="mdlTicket" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlTicket ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">LEVANTAR TICKET</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="ticket(false,0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row principal">
                <div class="col-12">
                    <label>Mensaje</label>
                    <textarea [(ngModel)]="asistenciaREQ.Comentario" class="form-control"></textarea>
                </div>
            </div>
            <div class="div-btns">
                <button class="btn-layout btn-med btnTableUp mt-4 float-right ml-5" (click)="crearTicket()">ENVIAR</button>    
            </div>    
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="mdlItemList" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlItemList ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">CAMBIAR DISPONIBILIDAD</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="agregarItem(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row principal">
                <div class="col-sm-12">
                    <span>DISPONIBILIDAD</span>
                </div>
                <div class="col-sm-12">
                    <input type="date" [(ngModel)]="disponibilidad" class="form-control">
                </div>
            </div>
            <div class="row tabla-catalogo">  
                <div class="col-12">
                    <table class="table table-responsive"> 
                        <thead>
                        <tr>
                            <th width="40%">NOMBRE ITEM</th>
                            <th width="40%">DISPONIBILIDAD</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let group of preguntas;">
                            <td>
                                <input type="checkbox" id="ckb-{{group.ReactivoItemEmpresaID}}">
                                {{group.Nombre}}
                            </td>
                            <td>{{group.Disponibilidad | date:'dd/MM/yyyy'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>             
            </div>
            <div class="div-btns">
                <button class="btn btn-layout btnTableUp float-right ml-5 pl-5 pr-5" (click)="guardarDisp()">GUARDAR</button>    
            </div>    
        </div>       
      </div>
    </div>
  </div>

  <div class="modal fade" id="mdlHistorial" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlHistorial ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">Historial</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="historial(false, 0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngFor="let group of preguntasHist.Historial;" class="mt-4 mb-4" style="border-top: 1px solid #495057;">
                <label class="react-text">{{preguntasHist.Nombre}}</label><br>
                <label>Fecha: {{group.UPDATE_DATE | date:'dd/MM/yyyy'}}</label>
                <div *ngIf="group.Vencimiento;">
                    <label>Fecha de Vencimiento</label>
                    <input disabled type="date" class="form-control mb-3" [(ngModel)]="group.VigenciaFIN">
                </div>
                <div *ngIf="preguntasHist.TipoFK == 2;">
                    <input disabled type="date" class="form-control" [(ngModel)]="group.Respuesta">
                </div>
                <div *ngIf="preguntasHist.TipoFK == 3;">
                    <button [hidden]="!group.Respuesta || group.Respuesta == '' || !group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3 mt-3" (click)="descargar(group.ReactivoItemEmpresaID)">DESCARGAR DOCUMENTO</button>
                    <button [hidden]="!group.Respuesta || group.Respuesta == '' || group.Respuesta.includes('back-eutdhl')" class="btn btn-secondary mb-3 mt-3" (click)="verDoc(group.Respuesta)">DESCARGAR DOCUMENTO</button>
                </div>
                <div *ngIf="preguntasHist.TipoFK == 8;">
                    <select disabled [(ngModel)]="group.Respuesta" class="form-control">
                        <option *ngFor="let item of preguntasHist.Catalogos" [value]="item.ID">{{item.Nombre}}</option>
                    </select>
                </div>
            </div>
        </div>       
      </div>
    </div>
  </div>
  
<div class="modal fade" id="edicionModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': modalQR ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-ch modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">{{nombreCode}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="preview(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <ngx-qrcode 
                        style="text-align: center;"
                        [elementType]="elementType"
                        [value]="value"
                        cssClass="aClass"
                        [errorCorrectionLevel]="errorCorrectionLevel">
                    </ngx-qrcode>
                </div>
            </div>
            <div class="div-btns row pt-3">
              <div class="col-12">
                <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="preview(false)" data-dismiss="modal" type="button">CERRAR</button>            
              </div>
            </div>          
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="mdlMotivo" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlMotivo ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">MOTIVO DE RECHAZO</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalMotivo(false,0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row principal">
                <div class="col-12">
                    <div class="form-group">
                        <label for="sltMotivo">Selecciona un motivo.</label>
                        <select name="sltMotivo" id="sltMotivo" #sltMotivo="ngModel" name="sltMotivo" [(ngModel)]="idMotivo" class="form-control">
                            <option value="0" disabled>Seleccionar...</option>
                            <option [value]="tl.Nombre" *ngFor="let tl of catalogTL">{{tl.Nombre}}</option>
                        </select>
                    </div>
                    <div class="form-group" *ngIf="idMotivo == 'Otro'">
                        <label>Especifica tu respuesta.</label>
                        <textarea [(ngModel)]="commentMotivo" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="div-btns">
                <button class="btn-layout btn-med btnTableUp mt-4 float-right ml-5" (click)="updateMotivo()">ENVIAR</button>    
            </div>    
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>