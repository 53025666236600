import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.css']
})
export class TarifasComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public id = +this._route.snapshot.paramMap.get('id');
  public cuestionario: any = [];
  public item: any = {};
  public datos:any[];
  public filter:any = {
    desde: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    hasta: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
    status: "TODOS"
  };
  searchText;

  //Datos cliente
  public client: any = {};
  public spn:any[];
  //Carga excel
  public modalCargar:boolean = false;
  public modalPreview:boolean = false;
  public modalErrors:boolean = false;
  public formdata = new FormData();
  public previewData: any = {};
  uploadedFiles: Array<File>;
  //Tarifas a enviar
  public tarifaSelect:any[];
  
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ClientesService,
    private _route: ActivatedRoute,
    private _router: Router,
    private datePipe: DatePipe
    ) { }

  ngOnInit(): void {
    this.verifyClaims();
    this.getCliente();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  public getCliente(){
    this._service.getCliente(this.id).then(response=>{
      this.client = response.content[0]; 
      this.spn = !this.client.NumProveedor || this.client.NumProveedor == '' ? [] : this.client.NumProveedor.split(",").map( Number );
      this.filter.spn = this.spn[0];
      this.getRegistros(1);
    });
  }

  public getRegistros(historial : number){
    this.loading.onLoadingRequest();
    this._service.getTarifas(this.filter.spn, this.id, this.filter.desde, this.filter.hasta, historial)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.datos = response.content;
        this.tarifaSelect = response.content.filter(x=> x.Status == 0).map((x) => { return x.EmpresaTarifaID });
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  regresar(){
    this._router.navigate(["/sa-rm/admin-client/" + this.id + "/ft/" + this.id]);
  }

  cambioStatus(){ 
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas enviar las tarifas seleccionadas a la empresa transportista?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.updateStatusTarifas(this.tarifaSelect,1)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getRegistros(1);
          }else{
            Swal.fire('Error', response.message, 'error');
          }
          
        }).catch(error=>{   
          this.loading.onDoneRequest();   
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  selectTarifa(id: number){
    const index = this.tarifaSelect.indexOf(id, 0);
    if (index > -1) {
      this.tarifaSelect.splice(index, 1);
    }else{
      this.tarifaSelect.push(id);
    }
  }

  public openCargaExcel(open : boolean){
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("Cargar archivo tarifas OTM");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.modalCargar = open;
  }

  obtenerExcel(event:any) { 
    if (event.target.files && event.target.files[0]) { 
      this.loading.onLoadingRequest();
      this.uploadedFiles = event.target.files;
      this.formdata = new FormData();
      for (let i = 0; i< this.uploadedFiles.length; i++){
        this.formdata.append("uploads[]", this.uploadedFiles[i], this.uploadedFiles[i].name);
      }
      this.loading.onDoneRequest();
    }
  }

  cargaExcel() { 
    if (!this.uploadedFiles) { 
      Swal.fire("Atención","Debes seleccionar un archivo para poder realizar la carga.","warning");
    }else if(!this.filter.spn){
      Swal.fire("Atención","Debes seleccionar un SPN al cual asignar la carga de las tarifas.","warning");
    }else{
      this.loading.onLoadingRequest();
      this.formdata.append("spn", this.filter.spn);
      this.formdata.append("empresaID", this.id.toString());
      this._service.PostTarifasCM(this.formdata).then((res)=>{
        console.log(res);
        this.loading.onDoneRequest();
        if(res.status == 200){
          this.previewData = res;
          $(".container").css('opacity',0.5);
          $("#sidebar-wrapper").css('opacity',0.5);
          if(res.errors.length > 0){
            $(".modal-title").html("Lista de Errores");
            this.modalErrors = true;
          }else{
            $(".modal-title").html("Vista Previa");
            this.modalPreview = true;
          }
        }else {
          Swal.fire("Error",res.message,"error");
        }
      });
    }
  }

  public openModalPreview(open : boolean){
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("Vista Previa");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.modalPreview = open;
  }

  public openModalErrors(open : boolean){
    if(open){     
      $(".container").css('opacity',0.5);
      $("#sidebar-wrapper").css('opacity',0.5);
      $(".modal-title").html("Lista de Errores");
    }else{      
      $(".container").css('opacity',1); 
      $("#sidebar-wrapper").css('opacity',1);      
    }
    this.modalErrors = open;
  }

  public guardarTarifas(){
    this.loading.onLoadingRequest();
    this._service.updateTarifas(this.previewData.content)
    .then(response=>{
      this.loading.onDoneRequest();
      this.openCargaExcel(false);
      this.openModalPreview(false);
      if(response.status == 200){
        Swal.fire('Éxito', "La carga de tarifas se completo de forma correcta", 'success')
        this.getRegistros(1);
      }else if(response.status == 500){
        Swal.fire("Error", response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public exportexcel(){
    /* table id is passed over here */   
    let element = document.getElementById('tableEmpresas'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Tarifas.xlsx');
  }
}
