import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from '../Layout/header/header.component'
import { ModuloQRComponent } from '../components/modulo-qr/modulo-qr.component'
import { ItemsComponent } from '../components/items/items.component'
import { UpdateitemComponent } from '../components/items/updateitem/updateitem.component'
import { Updateitem2Component } from '../components/items/updateitem2/updateitem2.component'
import { ItemReviewComponent } from '../components/items/item-review/item-review.component'
import { AsistenciaComponent } from '../components/asistencia/asistencia.component'
import { UsuariosComponent } from '../components/usuarios/usuarios.component'
import { RolesComponent } from '../components/roles/roles.component'
import { NivelComponent } from '../components/Catalogos/nivel/nivel.component';
import { PracticaComponent } from '../components/Catalogos/practica/practica.component';
import { GeneralComponent } from '../components/Catalogos/general/general.component';
import { CatalogoComponent } from '../components/Catalogos/general/catalogo/catalogo.component';
import { SidebarClComponent } from '../Layout/sidebar-cl/sidebar-cl.component'
import { SidebarCoComponent } from '../Layout/sidebar-co/sidebar-co.component'
import { SidebarCoclComponent } from '../Layout/sidebar-cocl/sidebar-cocl.component'
import { ConfigComponent } from '../Layout/sidebar-co/config/config.component'
import { LoginconsultorComponent } from '../components/loginconsultor/loginconsultor.component';
import { from } from 'rxjs';
import { GuardService } from 'src/app/services/login/guard/guard.service';
import { ClientComponent } from '../Layout/sidebar-co/client/client.component';
import { FtComponent } from '../Layout/sidebar-cocl/ft/ft.component';
import { ItemSublistCoclComponent } from '../Layout/sidebar-cocl/ft/item-sublist-cocl/item-sublist-cocl.component';
import { TarifasComponent } from '../Layout/sidebar-cocl/ft/tarifas/tarifas.component';
import { ClientQrComponent } from '../Layout/sidebar-cocl/ft/client-qr/client-qr.component';
import { NuevoClientComponent } from '../components/nuevo-client/nuevo-client.component';
import { DashboardComponent } from '../Layout/sidebar-cl/dashboard/dashboard.component';
import { ItemSublistComponent } from '../Layout/sidebar-cl/dashboard/item-sublist/item-sublist.component';
import { DashboardcoComponent } from '../Layout/sidebar-co/dashboardco/dashboardco.component';
import { DashboardAreaComponent } from '../Layout/sidebar-co/dashboardco/dashboard-area/dashboard-area.component';
import { DashboardEmpresaComponent } from '../Layout/sidebar-co/dashboardco/dashboard-empresa/dashboard-empresa.component';
import { DetalleComponent } from '../Layout/sidebar-co/dashboardco/dashboard-empresa/detalle/detalle.component';
import { ResumenComponent } from '../Layout/sidebar-co/dashboardco/dashboard-empresa/resumen/resumen.component';
import { AlertasComponent } from '../Layout/sidebar-cl/alertas/alertas.component';
import { ReactivoClComponent } from '../Layout/sidebar-cl/alertas/reactivo-cl/reactivo-cl.component';
import { AlertaCoComponent } from '../Layout/sidebar-co/alerta-co/alerta-co.component';
import { ItemlistCoComponent } from '../Layout/sidebar-co/alerta-co/itemlist-co/itemlist-co.component';
import { ReactivoCoComponent } from '../Layout/sidebar-co/alerta-co/itemlist-co/reactivo-co/reactivo-co.component';
import { TipoContratoComponent } from '../components/Catalogos/tipo-contrato/tipo-contrato.component';
import { AlertaContratoComponent } from '../components/contrato/alerta-contrato/alerta-contrato.component';
import { ContratoComponent } from '../components/contrato/contrato.component';
import { ReporteAdminComponent } from '../components/reporte-admin/reporte-admin.component';
import { ReporteIngresoComponent } from '../components/reporte-ingreso/reporte-ingreso.component';
import { TarifasTransportistaComponent } from '../Layout/sidebar-cl/tarifas-transportista/tarifas-transportista.component';
import { AlertasTarifasComponent } from '../Layout/sidebar-co/alertas-tarifas/alertas-tarifas.component';
import { InfoQRComponent } from '../components/info-qr/info-qr.component'
import { InfomultipleQRComponent } from '../components/infomultiple-qr/infomultiple-qr.component'
import { ConfigNoticiasComponent } from '../Layout/sidebar-co/config-noticias/config-noticias.component';
import { UpdateNoticiasComponent } from '../Layout/sidebar-co/config-noticias/update-noticias/update-noticias.component';
import { NoticiasComponent } from '../Layout/sidebar-co/noticias/noticias.component';


const app_routes:Routes=[    
    //Módulo 1: Change Management
    { path: '', component: LoginconsultorComponent },
    { path: 'info-qr/:id', component: InfoQRComponent },
    { path: 'infomultiple-qr/:id', component: InfomultipleQRComponent },
    { path: 'sa-rm', component: HeaderComponent,canActivate:[GuardService], children: [
   
      { path:'items', component:ItemsComponent },
      { path: 'updateitem/:item', component: UpdateitemComponent },
      { path: 'updateitem2/:item', component: Updateitem2Component },
      { path: 'itemreview/:id', component: ItemReviewComponent },
      { path: 'alertareactcl/:id', component: ReactivoClComponent },
      { path: 'alertareactco/:id', component: ReactivoCoComponent },

      { path:'usuarios', component:UsuariosComponent },
      { path: 'rol', component: RolesComponent },
      { path: 'area', component: NivelComponent },
      { path: 'grupo', component: PracticaComponent },
      { path: 'general', component: GeneralComponent },
      { path: 'catalog/:id', component: CatalogoComponent },
      { path: 'tipo-contrato', component: TipoContratoComponent },

      { path: 'nuevocliente', component: NuevoClientComponent },
    
      { path: 'client', component: SidebarClComponent, children: [
        { path: 'alertas/:id', component: AlertasComponent},
        { path: 'dashboard', component: DashboardComponent},
        { path: 'itemsublist/:id', component: ItemSublistComponent},
        { path: 'asistencia', component: AsistenciaComponent },
        { path: 'tarifas', component: TarifasTransportistaComponent },
        { path: 'noticias', component: NoticiasComponent }
       ]
      },

      { path: 'admin', component: SidebarCoComponent, children: [  
        { path: 'alertas/:id', component: AlertaCoComponent},     
        { path: 'alertaitem/:id', component: ItemlistCoComponent},     
         { path: 'config', component: ConfigComponent },
         { path: 'codes-qr', component:ModuloQRComponent },
         { path: 'reporte-admin', component: ReporteAdminComponent },
         { path: 'reporte-ingreso', component: ReporteIngresoComponent },
         { path: 'client/:deleted', component: ClientComponent },

         { path: 'dashboard/:id', component: DashboardcoComponent },
         { path: 'dashboard-area/:id', component: DashboardAreaComponent },
         { path: 'dashboard-empresa/:id', component: DashboardEmpresaComponent },
         { path: 'dashboard-detalle/:id', component: DetalleComponent },
         { path: 'dashboard-resumen/:id', component: ResumenComponent },

         { path: 'asistencia', component: AsistenciaComponent },
         { path: 'alerta-contrato', component: AlertaContratoComponent },
         { path: 'alerta-tarifa', component: AlertasTarifasComponent },
         { path: 'config-noticias', component: ConfigNoticiasComponent },
         { path: 'update-noticias/:id', component: UpdateNoticiasComponent },
         { path: 'noticias', component: NoticiasComponent }
        ]
      },

      { path: 'admin-client/:id', component: SidebarCoclComponent, children: [
        { path: 'ft/:id', component: FtComponent },
        { path: 'contrato/:id', component: ContratoComponent },
        { path: 'itemsublist/:id', component: ItemSublistCoclComponent},
        { path: 'tarifa/:id', component: TarifasComponent},
        { path: 'client-qr/:id', component: ClientQrComponent}
       ]
      }

     ]
    },
    { path: '**', pathMatch: 'full', redirectTo:''}//'/valuescan/consultor/client' }
]

export const AppRouting=RouterModule.forRoot(app_routes);