import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchRole;
  public obj:any = {};
  
  public roles:any[];
  
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:UsuariosService,
    public router: Router
    ) { }

  public mdlRol : boolean = false;

  ngOnInit(): void {
    this.verifyClaims();
    this.getRegistros();
    this.loading.onDoneRequest();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
  }

  public getRegistros(){    
    this.loading.onLoadingRequest();
    this._service.getRoles()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.roles = response.content;
          console.log(response.content)
        }else{
          Swal.fire("Sin registros","Aún no se han registrado usuarios","info");      
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

delete(id: number){ 
  Swal.fire({
    title: 'Atención',
    text: "¿Estas seguro que deseas eliminar el registro?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      this.loading.onLoadingRequest();
      this._service.deleteRoles(id)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          Swal.fire('Éxito', response.message, 'success');
          this.open(false, 0);        
        
          this.getRegistros();
        }else{
          Swal.fire('Error', response.message, 'error');
        }
        
      }).catch(error=>{   
        this.loading.onDoneRequest();   
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
  })
}

public open(open : boolean, id: number) : void {
  this.clearInput();
  this.obj.idRol = id;
  if(open){     
    $(".container").css('opacity',0.5);
    $("#sidebar-wrapper").css('opacity',0.5);
    $(".modal-title").html("CREAR ROL");
    if(id != 0){
      $(".modal-title").html("EDITAR ROL");
      let roleAct = this.roles.find(usr => usr.ID === id);
      console.log(roleAct)
      this.obj.Nombre = roleAct.Nombre;
      this.obj.Descripcion = roleAct.Descripcion;
      let PEmp = roleAct.permisoLists.find(per => per.PermisoID === 1);
      if(PEmp){
        this.obj.PEmp = true;
      }
      let PDash = roleAct.permisoLists.find(per => per.PermisoID === 2);
      if(PDash){
        this.obj.PDash = true;
      }
      let PConfig = roleAct.permisoLists.find(per => per.PermisoID === 3);
      if(PConfig){
        this.obj.PConfig = true;
      }
      let PEmpEdit = roleAct.permisoLists.find(per => per.PermisoID === 5);
      if(PEmpEdit){
        this.obj.PEmpEdit = true;
      }
    }
  }else{      
    $(".container").css('opacity',1); 
    $("#sidebar-wrapper").css('opacity',1);      
  }
  this.mdlRol = open;
}

update(){
  this.loading.onLoadingRequest();
  this._service.updateRole(this.obj)
  .then(response=>{
    this.loading.onDoneRequest();
    if(response.status == 200){
      Swal.fire('Éxito', response.message, 'success');
      this.open(false, 0);
      this.getRegistros();
    }else if (response.status == 500){      
      Swal.fire('Error', response.message, 'error');
    }else {
      Swal.fire("Atención",response.message,"info");
    }
  }).catch(error=>{ 
    this.loading.onDoneRequest();     
    Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
  })
}

clearInput(){
  this.obj.Nombre = "";
  this.obj.Descripcion = "";
  this.obj.PEmp = false;
  this.obj.PDash = false;
  this.obj.PConfig = false;
  this.obj.PEmpEdit = false;
};
}
