import { Injectable } from '@angular/core';
import { GLOBAL } from './../global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private url:string;
  userToken = localStorage.getItem('token').slice(1).slice(0,-1);

  constructor(private _http:HttpClient) { 
    this.url = GLOBAL.url + 'Usuarios';
  }

  enviarCorreo(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/enviarCorreo/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  getRegistros(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getUserList', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getRoles(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getRoles', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  getAreas(){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getCatalog?idCatalog=2', {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  update(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/update/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  delete(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/delete?idUser=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteByUser(correo:string){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteByUser?correo=' + correo, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateRole(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateRole/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteRoles(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteRoles?idRol=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateCatalog(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateCatalog/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteCatalog(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteCatalog?idCatalog=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  getCatalog(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getCatalog?idCatalog=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getTypeCatalog(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getTypeCatalog?idCatalog=' + id, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateTypeCatalog(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.post(this.url + '/updateTypeCatalog/', obj, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  deleteTypeCatalog(id:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });

    return this._http.delete(this.url + '/deleteTypeCatalog?idCatalog=' + id, {headers}).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }

  getPermisosUsuario(id:number){
    return this._http.get(this.url + 'usuarios/getPermisosUsuario/' + id)
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
 
  getSesion(id:number){
    return this._http.get(this.url + 'usuarios/getSesionUser/' + id)
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  guardarSesion(obj:any){
    return this._http.post(this.url + 'usuarios/guardarSesion', obj).toPromise()
    .then(res=>JSON.parse(JSON.stringify(res)));
  }
}