import { Injectable } from '@angular/core';
import { GLOBAL } from './../global';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NumberFormatterCallbackFunction } from 'highcharts';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private url:string;
  userToken = localStorage.getItem('token').slice(1).slice(0,-1);

  constructor(private _http:HttpClient) {
    this.url = GLOBAL.url + 'Dashboard';
  }

  getDashboardGeneral(rol:string, empresaID:number, flagExt:string){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getDashboardGeneral?rol=' + rol + '&empresaID=' + empresaID + '&flagExt=' + flagExt, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getDashboardAreas(flagExt:string){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getDashboardAreas?flagExt=' + flagExt, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getDashboardDetalle(rol:number,tp:number,tm:string,tl:number,flagExt:string){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getDashboardDetalle?rol=' + rol + '&tp=' + tp + '&tm=' + tm + '&tl=' + tl + '&flagExt=' + flagExt, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getRole(rol:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getRole?rol=' + rol, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getDashboardDetalleEmpresa(rol:number, empresaID:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getDashboardDetalleEmpresa?rol=' + rol + '&empresaID=' + empresaID, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getDashboardResumen(rol:number, tp:number, tm:String, tl:number, flagExt:string){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getDashboardResumen?rol=' + rol + '&tp=' + tp + '&tm=' + tm + '&tl=' + tl + '&flagExt=' + flagExt, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
  
  getReporteAdmin(empresaID:number, tm:String, status:String, itemID:number){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getReporteAdmin?empresaID=' + empresaID + '&tm=' + tm + '&status=' + status + '&itemID=' + itemID, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  getReporteIngreso(desde:Date, hasta:Date, status:String){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.get(this.url + '/getReporteIngreso?desde=' + desde + '&hasta=' + hasta + '&status=' + status, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }

  updateEmpresaAlerta(obj:any){
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.userToken}`
    });
    return this._http.post(this.url + '/updateEmpresaAlerta', obj, {headers})
    .toPromise().then(res=>JSON.parse(JSON.stringify(res)));
  }
}
