<div id="site_wrapper">
    <div class="contenedor-principal">
      <div class="row mapa-sitio">
        <div class="col-sm-12 col-md-9">
            <div>
                <a class="lbl-noActive" href="/#/sa-rm/admin/config">Configuraciones > </a><a href="/#/sa-rm/admin/config" class="lbl-noActive">&nbsp;ITEMS > </a><label class="lbl-active">&nbsp;Lista</label>
            </div>
            <h2>Items</h2> 
        </div>
      </div>
      <div class="row principal">
          <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
            <a href="/#/sa-rm/admin/config" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back.png" /></a>
              <input type="text" [(ngModel)]="searchText" class="buscador form-control" placeholder="Buscador">
          </div>
          <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2">
              <button class='btn-layout btn-fluid float-right btnTableUp' (click)='update(0)' type='button' style='margin-left:10px;'>
                  CREAR NUEVO
              </button>
          </div>
      </div>
      <div class="row tabla-catalogo">  
          <div class="col-12">
              <table class="table table-responsive"> 
                  <thead>
                  <tr>
                      <th width="40%">NOMBRE ITEM</th>
                      <th width="10%">GRUPO</th>
                      <th width="10%">DEFAULT</th>
                      <th width="10%">NUM. REACTIVOS</th>
                      <th width="10%">PUNTOS TOTALES</th>
                      <th width="20%">FECHA DE CREACIÓN</th>
                      <th class="no-sort"></th>
                      <th class="no-sort"></th>
                      <th class="no-sort"></th>
                      <th class="no-sort"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let group of catalog | item: searchText; ">
                      <td>{{group.Nombre}}</td>
                      <td>{{group.NombreGrupo}}</td>
                      <td>{{group.Default}}</td>
                      <td>{{group.totalPreguntas}}</td>
                      <td>{{group.Calificacion}}</td>
                      <td>{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                      <td>
                        <img class="btn-accion" src="../../../assets/IMG/preview-background-gray.png" (click)="preview(group.ItemID)" width="40" height="30" title="Preview">
                      </td>
                      <td>
                        <img class="btn-accion" src="../../../assets/IMG/edit-background-gray.png" (click)="update(group.ItemID)" width="40" height="30" title="Editar">
                      </td>
                      <td>
                        <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="newVersion(group.ItemID)" width="40" height="30" title="Nueva Versión">
                      </td>
                      <td>
                        <button class="btn btn-Eliminar btn-ch btnTableUp" (click)="delete(group.ItemID)">Eliminar</button>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>             
      </div>
    </div>
</div>
  
<div class="modal fade" id="previewModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlPreview ? 'block' : 'none', 'opacity': 1}"> 
  <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
    <div class="modal-content">
      <div class="modal-header mdl-hdr text-center">
        <h5 class="modal-title w-100">PREVIEW</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="preview(0)">
          <span aria-hidden="true" class="mdl-close" >&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <app-preview [objPreview]="objPreview"></app-preview>
      </div>       
    </div>
  </div>
</div>

<app-loading-efect></app-loading-efect>