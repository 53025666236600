<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Alertas Pendientes Contratos</h2> 
            </div>
        </div>
        <div class="row principal">
            <div class="col-12">
                <input type="text" [(ngModel)]="searchAsistencia" class="buscador form-control"  placeholder="Buscador">
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="20%">EMPRESA</th>
                            <th width="10%">NACIONAL/EXTRANJERA</th>
                            <th width="20%">STATUS</th>
                            <th width="50%">DESCRIPCION</th>
                            <th width="10%">FECHA DE CREACIÓN</th>
                            <th width="5%">CONTRATO</th>
                            <th width="5%">DOCUMENTO</th>
                            <th width="5%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog | alertaContrato: searchAsistencia;">
                            <td routerLink="/sa-rm/admin-client/{{group.EmpresaID}}/ft/{{group.EmpresaID}}" style="cursor: pointer;">{{group.Nombre}}</td>
                            <td *ngIf="!group.LineaExtranjera">NACIONAL</td>
                            <td *ngIf="group.LineaExtranjera">EXTRANJERA</td>
                            <td>{{group.StatusNombre}}</td>
                            <td>{{group.Descripcion}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <div *ngIf="group.ContratoSolicitudID != null">
                                    <img class="btn-accion" src="../../../assets/IMG/preview-background-gray.png" (click)="preview(group.ContratoSolicitudID)" width="40" height="30" title="Ver contrato">
                                </div>
                            </td>
                            <td>
                                <div *ngIf="group.ContratoExtranjeraID != null">
                                    <img class="btn-accion" src="../../../assets/IMG/preview-background-gray.png" (click)="previewExt(group.ContratoExtranjeraID)" width="40" height="30" title="Ver contrato">
                                </div>
                            </td>
                            <td>
                                <div *ngIf="group.StatusID == 1 && !group.LineaExtranjera">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="accionStatus1(true, group.EmpresaID)" width="40" height="30" title="Seleccionar tipo">
                                </div>
                                <div *ngIf="(group.StatusID == 1 || group.StatusID == 11) && group.LineaExtranjera">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="accionStatus1Ext(true, group.EmpresaID)" width="40" height="30" title="Seleccionar tipo">
                                </div>
                                <div *ngIf="group.StatusID == 2 && group.LineaExtranjera">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="accionStatus2Ext(true, group.ContratoExtranjeraID)" width="40" height="30" title="Validar Documento">
                                </div>
                                <div *ngIf="group.StatusID == 21 || group.StatusID == 2111">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="accionStatus2(true, group.EmpresaID, group.LineaExtranjera)" width="40" height="30" title="Cargar Contrato">
                                </div>
                                <div *ngIf="group.StatusID == 211">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="accionStatus3(true, group.ContratoSolicitudID, group.LineaExtranjera)" width="40" height="30" title="Validar Contrato">
                                </div>
                                <div *ngIf="group.StatusID == 22">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="openVisitSeg(true, group.EmpresaID)" width="40" height="30" title="Abrir Visita de Seguridad">
                                </div>
                                <div *ngIf="group.StatusID == 222">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="openNoAprobada(group.EmpresaID)" width="40" height="30" title="Seleccionar tipo">
                                </div>
                                <div *ngIf="group.StatusID == 223">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="openDeclinada(group.EmpresaID)" width="40" height="30" title="Seleccionar tipo">
                                </div>
                                <div *ngIf="group.StatusID == 3 && !group.LineaExtranjera">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="openFinalizar(group.EmpresaID)" width="40" height="30" title="Seleccionar tipo">
                                </div>
                                <div *ngIf="group.StatusID == 3 && group.LineaExtranjera">
                                    <img class="btn-accion" src="../../../assets/IMG/newVersion-background-gray.png" (click)="openFinalizarExt(group.EmpresaID)" width="40" height="30" title="Seleccionar tipo">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<div class="modal fade" id="accionStatus1Modal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': accionStatus1Modal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="accionStatus1(false, 0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #createForm1="ngForm" (ngSubmit)="updateStatus1()" autocomplete="off" appIdentityRevealed>
                <div class="form-group">
                    <label for="tipoC">NOMBRE</label>
                    <select class="form-control" #tipoC="ngModel" name="tipoC" [(ngModel)]="obj.TipoContratoID" placeholder="Ingresa texto"  required>
                      <option value="{{group.id}}" *ngFor="let group of tiposContratos;">{{group.Nombre}}</option>
                    </select>
                    <div *ngIf="tipoC.invalid"
                        class="lbl-error">
                        <span *ngIf="tipoC.errors.required">
                            El tipo de contrato es requerido
                        </span>            
                    </div>
                </div>
                <div class="form-group">
                    <label for="Prioridad">PRIORIDAD</label>
                    <select class="form-control" #Prioridad="ngModel" name="Prioridad" [(ngModel)]="obj.Prioridad">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="NivelSeguridad">NIVEL DE SEGURIDAD</label>
                    <select class="form-control" #NivelSeguridad="ngModel" name="NivelSeguridad" [(ngModel)]="obj.NivelSeguridad">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="accionStatus1(false, 0)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btn-layout btnTableUp" [disabled]="createForm1.invalid">GUARDAR</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="accionStatus2Modal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': accionStatus2Modal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="accionStatus2(false, 0, false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #createForm2="ngForm" (ngSubmit)="updateStatus1()" autocomplete="off" appIdentityRevealed>
                <div class="form-group">
                    <input type="file" name="URLPDF" accept=".pdf" (change)="readUrl($event)">
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="accionStatus2(false, 0, false)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btn-layout btnTableUp">GUARDAR</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="accionStatus3Modal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': accionStatus3Modal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="accionStatus3(false, 0, false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #createForm3="ngForm" autocomplete="off" appIdentityRevealed>
                <div class="div-btns row pt-3">                     
                    <div class="col-12">
                        <label style="float: left;">¿Desea autorizar el contrato elaborado por Jurídico?</label>         
                    </div>                
                    <div class="col-12">
                        <label style="float: left;">Comentarios</label>
                        <textarea [(ngModel)]="contr.Comentario" name="Comentario" class="form-control"></textarea>
                    </div>
                    <div *ngIf="!obj.LineaExtranjera" class="col-12 mt-3">
                        <button class="btn-danger btn-med float-left mb-3" (click)="updateStatus3(false)">RECHAZAR</button>
                        <button class="btn-success btn-med float-left mb-3" (click)="updateStatus3(true)">AUTORIZAR</button>
                    </div>         
                    <div *ngIf="obj.LineaExtranjera" class="col-12 mt-3">
                        <button class="btn-danger btn-med float-left mb-3" (click)="autorizarContratoExt(false)">RECHAZAR</button>
                        <button class="btn-success btn-med float-left mb-3" (click)="autorizarContratoExt(true)">AUTORIZAR</button>
                    </div>       
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="accionStatus4Modal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': accionStatus4Modal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openVisitSeg(false, 0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row ml-0 mr-0 pt-2 pb-2">
                <div class="col-12">
                    <div class="form-group">
                        <label for="vNombre">NOMBRE EMPRESA</label>
                        <input type="text" class="form-control" id="vNombre" [(ngModel)]="visit.Nombre" disabled>
                    </div>
                    <div class="form-group">
                        <label for="vPrioridad">PRIORIDAD</label>
                        <input type="text" class="form-control" id="vPrioridad" [(ngModel)]="visit.Prioridad" disabled>
                    </div>
                    <div class="form-group">
                        <label for="vNivelSeguridad">NIVEL DE SEGURIDAD</label>
                        <input type="text" class="form-control" id="vNivelSeguridad" [(ngModel)]="visit.NivelSeguridad" disabled>
                    </div>
                    <div class="form-group">
                        <label for="vNombreContacto">NOMBRE CONTACTO</label>
                        <input type="text" class="form-control" id="vNombreContacto" [(ngModel)]="visit.NombreContacto" disabled>
                    </div>
                    <div class="form-group">
                        <label for="vTelefono">TELÉFONO</label>
                        <input type="text" class="form-control" id="vTelefono" [(ngModel)]="visit.Telefono" disabled>
                    </div>
                    <div class="form-group">
                        <label for="vCorreo">CORREO</label>
                        <input type="text" class="form-control" id="vCorreo" [(ngModel)]="visit.Correo" disabled>
                    </div>
                    <div class="form-group">
                        <label for="vDirOficina">DIRECCIÓN OFICINA</label>
                        <input type="text" class="form-control" id="vDirOficina" [(ngModel)]="visit.DirOficina" disabled>
                    </div>
                    <div class="form-group">
                        <label for="vDirPatio">DIRECCIÓN PATIO</label>
                        <input type="text" class="form-control" id="vDirPatio" [(ngModel)]="visit.DirPatio" disabled>
                    </div>
                    <div class="form-group">
                        <label for="vSector">Sector en que actualmente carga</label>
                        <input type="text" class="form-control" id="vSector" [(ngModel)]="visit.Sector" disabled>
                    </div>
                    <div class="form-group">
                        <label for="vFamilia">Familia de Productos para Co-Embarque</label>
                        <input type="text" class="form-control" id="vFamilia" [(ngModel)]="visit.Familia" disabled>
                    </div>
                </div>
                <div class="col-6">
                  <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="openVisitSeg(false, 0)" data-dismiss="modal" type="button">CANCELAR</button>            
                </div>
                <div class="col-6">     
                  <a class="btn btn-fluid btn-layout btnTableUp" href="/#/sa-rm/admin-client/8/itemsublist/{{visit.EmpresaID}},8">IR A VISITA DE CERTIFICACIÓN</a>    
                </div>
            </div>   
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="accionStatus1ExtModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': accionStatus1ExtModal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="accionStatus1Ext(false, 0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #createForm5="ngForm" (ngSubmit)="updateStatusEXT()" autocomplete="off" appIdentityRevealed>
                <div class="form-group">
                    <label for="tipoC2">TIPO DE CONTRATO</label>
                    <select class="form-control" #tipoC2="ngModel" name="tipoC2" [(ngModel)]="obj.TipoContratoID" placeholder="Ingresa texto"  required>
                      <option value="{{group.id}}" *ngFor="let group of tiposContratos;">{{group.Nombre}}</option>
                    </select>
                    <div *ngIf="tipoC2.invalid"
                        class="lbl-error">
                        <span *ngIf="tipoC2.errors.required">
                            El tipo de contrato es requerido
                        </span>            
                    </div>
                </div>
                <div class="form-group">
                    <input type="file" name="URLPDF2" accept=".pdf" (change)="readUrlEXT($event)">
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="accionStatus1Ext(false, 0)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btn-layout btnTableUp" [disabled]="createForm5.invalid">GUARDAR</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
</div>

<div class="modal fade" id="accionStatus2ExtModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': accionStatus2ExtModal ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="accionStatus2Ext(false, 0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="div-btns row pt-3">                     
                <div class="col-12">
                    <label style="float: left;">¿Desea autorizar el documento Alta y Cambio de Datos de Proveedores de DHL?</label>         
                </div>                
                <div class="col-12">
                    <label style="float: left;">Comentarios</label>
                    <textarea [(ngModel)]="contr.Comentario" name="Comentario" class="form-control"></textarea>
                </div>
                <div class="col-12 mt-3">
                    <button class="btn-danger btn-med float-left mb-3" (click)="autorizarDocumento(false)">RECHAZAR</button>
                    <button class="btn-success btn-med float-left mb-3" (click)="autorizarDocumento(true)">AUTORIZAR</button>
                </div>         
            </div>  
        </div>       
      </div>
    </div>
</div>
<app-loading-efect></app-loading-efect>