import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Highcharts from 'highcharts';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { ActivatedRoute, Router } from '@angular/router';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let Solid = require('highcharts/modules/solid-gauge');
let Exporting = require('highcharts/modules/exporting');
let Export = require('highcharts/modules/export-data');
let Accessibility = require('highcharts/modules/accessibility');
let Drilldown = require('highcharts/modules/drilldown');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
Solid(Highcharts);
Exporting(Highcharts);
Export(Highcharts);
Accessibility(Highcharts);
Drilldown(Highcharts);

@Component({
  selector: 'app-dashboard-area',
  templateUrl: './dashboard-area.component.html',
  styleUrls: ['./dashboard-area.component.css']
})
export class DashboardAreaComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public obj:any = {};
  selectArea:any;

  public flagEXT = this._route.snapshot.paramMap.get('id');
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:DashboardService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(){
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.add("body-dash");
    }
    this.verifyClaims();
    this.getRegistros();
    this.loading.onDoneRequest();
  }

  ngOnDestroy() {
    // remove the class form body tag
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.remove("body-dash");
    }
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Dashboard');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getDashboardAreas(this.flagEXT)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.obj = response;
        let factor = 10 ** 2;
        this.obj.DG.PEROperaciones = Math.round(this.obj.DG.PEROperaciones * factor)/ factor;
        this.obj.DG.PERContrato = Math.round(this.obj.DG.PERContrato * factor)/ factor;
        this.obj.DG.PERDeseable = Math.round(this.obj.DG.PERDeseable * factor)/ factor;
        this.obj.ListDG = response.ListDG.map((x) =>
        {
          x.DArea.PEROperaciones = Math.round(x.DArea.PEROperaciones * factor)/ factor;
          x.DArea.PERContrato = Math.round(x.DArea.PERContrato * factor)/ factor;
          x.DArea.PERDeseable = Math.round(x.DArea.PERDeseable * factor)/ factor;
          return x
        });
        console.log(this.obj);
        this.graph();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public graph(){
    var opciones:any = {
      title: {
          text: ''
      },
      xAxis: {
          categories: []
      },
      yAxis: {
          min: 0,
          max: 100,
          title: {
              text: 'Porcentaje completado'
          }
      },
      labels: {
          items: [{
              html: '% Completado Total',
              style: {
                  left: '50px',
                  top: '18px',
                  color: ( // theme
                      Highcharts.defaultOptions.title.style &&
                      Highcharts.defaultOptions.title.style.color
                  ) || 'black'
              }
          }]
      },
      series: [{
          type: 'column',
          name: 'Mandatorios para visita de certificación',
          data: []
      }, {
          type: 'column',
          name: 'Mandatorios para contrato',
          data: []
      }, {
          type: 'column',
          name: 'No mandatorios',
          data: []
      }, {
          type: 'pie',
          name: '%',
          data: [{
              name: 'Mandatorios para contrato',
              y: this.obj.DG.PERContrato
          }, {
              name: 'Mandatorios para visita de certificación',
              y: this.obj.DG.PEROperaciones
          }, {
              name: 'No mandatorios',
              y: this.obj.DG.PERDeseable
          }],
          center: [100, 80],
          size: 100,
          showInLegend: false,
          dataLabels: {
              enabled: false
          }
      }],
      credits:{
        enabled: false
      }
    }
    this.obj.ListDG.forEach(function(element){
      opciones.xAxis.categories.push(element.Rol);
      opciones.series[0].data.push(element.DArea.PEROperaciones);
      opciones.series[1].data.push(element.DArea.PERContrato);
      opciones.series[2].data.push(element.DArea.PERDeseable);
    });
    Highcharts.chart('graph', opciones);
  }

  gotoDashboard(){
    this._router.navigate(['/sa-rm/admin/dashboard-empresa/' + this.selectArea + ',' + this.flagEXT]);
  }
}
