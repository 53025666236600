import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Highcharts from 'highcharts';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as XLSX from 'xlsx'; 

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let Solid = require('highcharts/modules/solid-gauge');
let Exporting = require('highcharts/modules/exporting');
let Export = require('highcharts/modules/export-data');
let Accessibility = require('highcharts/modules/accessibility');
let Drilldown = require('highcharts/modules/drilldown');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
Solid(Highcharts);
Exporting(Highcharts);
Export(Highcharts);
Accessibility(Highcharts);
Drilldown(Highcharts);

@Component({
  selector: 'app-dashboard-empresa',
  templateUrl: './dashboard-empresa.component.html',
  styleUrls: ['./dashboard-empresa.component.css']
})
export class DashboardEmpresaComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public rolID = +this._route.snapshot.paramMap.get('id').split(",")[0];
  public flagEXT = this._route.snapshot.paramMap.get('id').split(",")[1];
  public obj:any = {};
  public role:string = "";
  public catalogTL:any[];
  selectTP:number = 3;
  selectTM:string = "Todos";
  selectTL:number = 138;
  tableEmpresas:boolean = true;
  searchDash;
  fileName= 'ExcelSheet.xlsx';  

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:DashboardService,
    private _serviceUsuario:UsuariosService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(): void {
    // get html body element
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.add("body-dash");
    }
    this.verifyClaims();
    this.getRole();
    this.getCatalog();
    this.loading.onDoneRequest();
  }

  ngOnDestroy() {
    // remove the class form body tag
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.remove("body-dash");
    }
  }

  public getRole(){
    this.loading.onLoadingRequest();
    this._service.getRole(this.rolID)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.role = response.message;
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getDashboardDetalle(this.rolID, this.selectTP, this.selectTM, this.selectTL, this.flagEXT)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.obj = response;
        let factor = 10 ** 2;
        this.obj.DDG = this.obj.DDG.map((x) =>
        {
          x.PorcentageCarga = Math.round(x.PorcentageCarga * factor)/ factor;
          x.PorcentageCert = Math.round(x.PorcentageCert * factor)/ factor;
          x.PorcentageRech = Math.round(x.PorcentageRech * factor)/ factor;
          return x
        });
        console.log(this.obj);
        this.tableEmpresas = false
        this.graph();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }
  
  public graph(){
    var opciones:any = {
      chart: {
          type: 'bar'
      },
      title: {
          text: ''
      },
      xAxis: {
          categories: []
      },
      tooltip: {
          valueSuffix: '%'
      },
      yAxis: {
          min: 0,
          max: 100,
          title: {
              text: 'Porcentaje completado'
          }
      },
      legend: {
          reversed: true
      },
      series: [{
        type: 'column',
          name: 'Carga',
          data: []
      },{
        type: 'column',
        name: 'Validación',
        data: []
      },{
        type: 'column',
        name: 'Rechazado',
        data: []
    }],
      credits:{
        enabled: false
      }
    }
    this.obj.DDG.forEach(function(element){
      opciones.xAxis.categories.push(element.Nombre);
      opciones.series[0].data.push(element.PorcentageCarga);
      opciones.series[1].data.push(element.PorcentageCert);
      opciones.series[2].data.push(element.PorcentageRech);
    });
    Highcharts.chart('graph', opciones);
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Dashboard');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('tableEmpresas'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

     /* save to file */
     XLSX.writeFile(wb, this.fileName);
  }

  public getCatalog(){    
    this.loading.onLoadingRequest();
    this._serviceUsuario.getCatalog(17)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogTL = response.content;
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  validateId(message: string) {
    let flag = this.obj.DDG.some(x=>x.Nombre == message || message == "TOTAL")
    return flag;
  }
}
