import { Component, OnInit } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Md5} from 'ts-md5/dist/md5';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-nuevo-client',
  templateUrl: './nuevo-client.component.html',
  styleUrls: ['./nuevo-client.component.css']
})
export class NuevoClientComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public Emp:any = {};
  public userEmp:string;
  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:ClientesService,
    private _router:Router
  ) { }

  ngOnInit(){
    this.verifyClaims();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  public update(){
    this.Emp.EmpresaID = 0;
    this.Emp.users = [this.userEmp];
    this._service.updateEmpresa(this.Emp)
        .then(response=>{
          if(response.status == 200){
            this.loading.onDoneRequest();
            Swal.fire("Registro exitoso","Se ha dado de alta la empresa de manera exitosa.","success")
            .then(rs=>{
              this._router.navigate(['/sa-rm/admin-client/' + response.EmpresaID + '/ft/' + response.EmpresaID]);
            });
          }else{
            Swal.fire('Error', response.message, 'error');
          }        
        }).catch(error=>{   
          this.loading.onDoneRequest();   
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
  }
}
