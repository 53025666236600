<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row">
            <div class="col-sm-12 btn-regresar-2">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Status Contrato {{nombre}}</h2> 
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="20%">EMPRESA</th>
                            <th width="20%">STATUS</th>
                            <th width="50%">DESCRIPCION</th>
                            <th width="10%">FECHA DE CREACIÓN</th>
                            <th width="5%">CONTRATO</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog;">
                            <td>{{group.Nombre}}</td>
                            <td>{{group.StatusNombre}}</td>
                            <td>{{group.Descripcion}}</td>
                            <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <div *ngIf="group.ContratoSolicitudID != null">
                                    <img class="btn-accion" src="../../../assets/IMG/preview-background-gray.png" (click)="preview(group.ContratoSolicitudID)" width="40" height="30" title="Ver contrato">
                                </div>
                            </td>
                            <td>
                                <div *ngIf="group.StatusID == 2111">
                                    <img class="btn-accion" src="../../../assets/IMG/circulo_rojo.png" width="20" height="20">
                                </div>
                                <div *ngIf="group.FlagActivo && group.StatusID != 2111">
                                    <img class="btn-accion" src="../../../assets/IMG/circulo_verde.png" width="20" height="20">
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>        
            <div class="col-12" style="margin-top: 20px;">
                <button class="btn btn-danger" style="margin-top: -10px !important;" (click)="resetContract()">Reiniciar Proceso Contrato</button>
            </div>     
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>