export var GLOBAL={
    // url:'http://20.36.21.67:8010/api/'
    // url:'https://back-eutdhl.sa-rm.com/api/',
    // urlIMG: 'https://back-eutdhl.sa-rm.com/'
    // url:'https://backeutd-qa.sa-rm.com/api/',
    // urlIMG: 'https://backeutd-qa.sa-rm.com/'
     url:'https://eutsample-back.sa-rm.com/api/',
     urlIMG: 'https://eutsample-back.sa-rm.com/'
    // url: 'http://localhost:62504/api/',
    // urlIMG: 'http://localhost:62504/'
}