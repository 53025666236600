import { Component, OnInit, ɵConsole  } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { AuthService } from 'src/app/services/login/auth/auth.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { faSignOutAlt as fasSignOut} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  identity = JSON.parse(localStorage.getItem('identity_user'));
  private sesionCookies = this.identity.Sesion;
  private sesionBD: string = '';
  private objSesion: any = {};
  fasSignOut = fasSignOut;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _serviceUsuario: UsuariosService
  ) { }

    ngOnInit() {
      
    }
    
    ngAfterViewInit(){  
      
    }
  
    ngAfterContentInit(){   
      
    }
    
    ngAfterContentChecked(){    
    }

    async getSesion(){      
      this._serviceUsuario.getSesion(this.identity.UsuarioId)
      .then(response =>{
         this.sesionBD = response.content;         
         if(this.sesionCookies == this.sesionBD){
            this.objSesion.usuarioid = this.identity.UsuarioId;
            this.objSesion.sesion = '';
            this._serviceUsuario.guardarSesion(this.objSesion);
         }
      });
    }


    async logout(){
      this._auth.logOut()
      .then(response=>{
        this._router.navigate(['/login-consultor']);
      });
    }
}
