import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login/login.service';
import Swal from 'sweetalert2';
import {Md5} from 'ts-md5/dist/md5';

@Component({
  selector: 'app-loginconsultor',
  templateUrl: './loginconsultor.component.html',
  styleUrls: ['./loginconsultor.component.css']
})
export class LoginconsultorComponent implements OnInit {
  public obj:any = {};
  identity = JSON.parse(localStorage.getItem('identity_user'));
  public btnContinuar:boolean = false;
  public btnLogin:boolean = true;

  constructor(
    private _serviceLogin: LoginService,
    public router: Router
  ) { }

  ngOnInit(): void {
    if(this.identity != null && this.identity.Rol != 1){
      this.router.navigate(['/sa-rm/admin']);
    }else if(this.identity != null && this.identity.Rol == 1){
      this.router.navigate(['/sa-rm/client/dashboard']);
    }  
  }

  goToMenu(rol) {
    if(rol != 1){
      this.router.navigate(['/sa-rm/admin']);
    }else if(rol == 1){
      this.router.navigate(['/sa-rm/client/dashboard']);
    }  
  }

  continuar(){
    if(this.obj.usuario == undefined || this.obj.usuario == ""){
      Swal.fire("Atención","Debes ingresar tu correo electrónico para poder continuar","warning");
      return false;
    }
    else if(!this.obj.terminos){
      Swal.fire("Atención","Debes aceptar los términos y condiciones para poder continuar","warning");
      return false;
    }
    else{
      if(this.validateEmail(this.obj.usuario)){
        this.obj.sesion = Md5.hashStr(Math.random().toString(36).substring(7));
        this._serviceLogin.validateUser(this.obj)
        .then(response=>{
          if(response.status == 200){
            this._serviceLogin.getToken(this.obj)
            .then(responseTK=>{
              if(responseTK.status == 200){
                this.btnContinuar = true;
                this.btnLogin = false;
              }else{
                Swal.fire("Error de token",responseTK.message,"error");
              }
            })
            .catch(error=>{
              Swal.fire("Error de conexión con el servidor",error.toString(),"error");
            });
          }else if(response.status == 401){
            Swal.fire("Atención","El correo electrónico que ingresaste no tiene acceso. Por favor, revisa e inténtalo de nuevo.","warning");
          }else{
            Swal.fire("Error al validar",response.message,"error");
          }
        })
        .catch(error=>{
          Swal.fire("Error de conexión con el servidor",error.toString(),"error");
        })
      }else{
        Swal.fire("Usuario no válido","Se requiere formato de email","warning");
        return false;
      }
    }
  }

  regresar(){
    this.btnContinuar = false;
    this.btnLogin = true;
    this.obj.password = undefined;
    this.obj.usuario = undefined;
    this.obj.terminos = false;
  }

  login(){    
    if(this.obj.password == undefined || this.obj.usuario == undefined){
      Swal.fire("Atención","Debes ingresar tu token de acceso para poder continuar","warning");
      return false;
    }else{
        var pass = this.obj.password;
        this.obj.sesion = Md5.hashStr(Math.random().toString(36).substring(7));
        this._serviceLogin.login(this.obj)
        .then(response=>{
          localStorage.setItem('token', JSON.stringify(response.access_token));
          this._serviceLogin.getUser(this.obj, true)
          .then(responseUsr=>{
            localStorage.setItem('identity_user', JSON.stringify(responseUsr.user));
            console.log(JSON.parse(localStorage.getItem('identity_user')));
            this.goToMenu(responseUsr.user.Rol);
          });
        })
        .catch(error=>{
          Swal.fire("Atención","El Token de acceso que ingresaste es incorrecto. Por favor, revisa e inténtalo de nuevo o genera un nuevo Token.","warning");
        })
      this.obj.password = pass;
    }
  }

  validateEmail(email) 
  {        
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
  }

}
