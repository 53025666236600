import { Component, Inject, OnInit  } from '@angular/core';
import * as $ from 'jquery';
import { faTimes as fasTimes, faNewspaper} from '@fortawesome/free-solid-svg-icons';
import { faBars as fasBars} from '@fortawesome/free-solid-svg-icons';
import { faCircle as fasCircle} from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt as fasSignOut} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope as fasEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle as faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import { GLOBAL } from './../../services/global';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/services/login/auth/auth.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { AsistenciaService } from 'src/app/services/asistencia/asistencia.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-sidebar-cl',
  templateUrl: './sidebar-cl.component.html',
  styleUrls: ['./sidebar-cl.component.css']
})

export class SidebarClComponent implements OnInit {
  private url:string;
  identity = JSON.parse(localStorage.getItem('identity_user'));
  private sesionCookies = this.identity.Sesion;
  private sesionBD: string = '';
  private objSesion: any = {};
  fasTimes = fasTimes;
  fasEnvelope = fasEnvelope;
  fasBars = fasBars;
  fasCircle = fasCircle;
  fasSignOut = fasSignOut;
  faNewspaper = faNewspaper;
  faQuestionCircle = faQuestionCircle;
  toggle:boolean = false;
  //Variables para Alertas
  pendientes:number = 0;
  Rechazados:number = 0;
  porCaducar:number = 0;
  caducados:number = 0;
  asistenciasNuevo:number = 0;
  asistenciasCurso:number = 0;
  alertaTarifas:number = 0;
  //Variables para Flujo Tickets
  public mdlTicket: boolean = false;
  public asistenciaREQ: any = {};

  //Modal Contacto
  public mdlReport : boolean = false;
  public obj:any = {};

  constructor(
    private _auth: AuthService,
    private _serviceUsuario: UsuariosService,
    private _serviceCliente: ClientesService,
    public router: Router,
    private _asistService:AsistenciaService,
    @Inject(DOCUMENT) document: any
  ) { 
    
  }

  ngOnInit() {
    console.log(this.identity);
    this.getAlertas();
  }
    
  getAlertas(){
    this._serviceCliente.getAlertas(this.identity.empresa.EmpresaID)
    .then(response=>{
      if(response.status == 200){
        this.pendientes = response.pendientes;
        this.Rechazados = response.Rechazados;
        this.porCaducar = response.porCaducar;
        this.caducados = response.caducados;
        this.asistenciasNuevo = response.asistenciasNuevo;
        this.asistenciasCurso = response.asistenciasCurso;
        this.alertaTarifas = response.alertaTarifas;
        (async () => { 
          await this.delay(10000);
          // Do something after
          this.getAlertas();
        })();
      }else if (response.status == 500){      
        console.log(response.message);
      }else {
        console.log(response.message);
      }
    }).catch(error=>{ 
      console.log( error.toString());
    })
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  openAlertas(tipo:number){
    this.router.navigate(['/sa-rm/client/alertas/' + tipo]);
  }

  public ticket(flag:boolean){
    if(flag){
      this.asistenciaREQ.AsistenciaID = 0;
      this.asistenciaREQ.ReactivoItemEmpresaID = 0;
      this.asistenciaREQ.Status = 1;
      this.asistenciaREQ.UsuarioID = this.identity.UsuarioID;
      this.asistenciaREQ.Comentario = "";
    }
    this.mdlTicket = flag;
  }
  
  public crearTicket(){
    this._asistService.updateAsistencia(this.asistenciaREQ)
    .then(response=>{
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.mdlTicket = false;
            });
      }    
    }).catch(error=>{
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }

    ngAfterViewInit(){  
      
    }
  
    ngAfterContentInit(){   
      
    }
    
    ngAfterContentChecked(){    
    }

    openmenu(){
      if(this.toggle){
        this.toggle = false;
      }else{
        this.toggle = true;
      }
    }
    
    closesidebar() {
      $(".page-wrapper").removeClass("toggled");
    }

    showsidebar() {
      $(".page-wrapper").addClass("toggled");
    }

    async getSesion(){      
      this._serviceUsuario.getSesion(this.identity.UsuarioId)
      .then(response =>{
         this.sesionBD = response.content;         
         if(this.sesionCookies == this.sesionBD){
            this.objSesion.usuarioid = this.identity.UsuarioId;
            this.objSesion.sesion = '';
            this._serviceUsuario.guardarSesion(this.objSesion);
         }
      });
    }

    async logout(){
      await this.getSesion();      

      this._auth.logOut();
      this.router.navigate(['/login-consultor']);
    }

    asistencia(){
      this.router.navigate(['/sa-rm/client/asistencia']);
    }

    dashboard(){
      this.router.navigate(['/sa-rm/client/dashboard']);
    }

    tarifas(){
      this.router.navigate(['/sa-rm/client/tarifas']);
    }

    noticias(){
      this.router.navigate(['/sa-rm/client/noticias']);
    }

    public openContact(open : boolean) : void {
      if(open){     
        $(".container").css('opacity',0.5);
        $("#sidebar-wrapper").css('opacity',0.5);
      }else{      
        $(".container").css('opacity',1); 
        $("#sidebar-wrapper").css('opacity',1);      
      }
      this.mdlReport = open;
    }

    enviarCorreo(){
      this._serviceUsuario.enviarCorreo(this.obj)
      .then(response=>{
        if(response.status == 200){
          Swal.fire('Mensaje enviado', 'Tu mensaje ha sido enviado de forma correcta', 'success');
        }else {
          Swal.fire('Error', response.message, 'error');
        }
      }).catch(error=>{ 
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
}
