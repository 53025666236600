import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './../../global';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private _http:HttpClient
  ) { }

  getIdentity(){
    let identity = JSON.parse(localStorage.getItem('identity_user'));
    let token = JSON.parse(localStorage.getItem('token'));
    if(identity && token){
      return identity;
    }else{
      return null;
    }
  }

  logOut(){
    const userToken = localStorage.getItem('token').slice(1).slice(0,-1);
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${userToken}`
    });
    
    const url = GLOBAL.url + 'Usuarios/registrarCierre';
    return this._http.post(url, null, {headers})
    .toPromise().then(res=>
      {
        localStorage.removeItem('identity_user');
        localStorage.clear();
        JSON.parse(JSON.stringify(res))
      });
  }
}
