<div id="site_wrapper">
    <div class="contenedor-principal">
      <div class="row mapa-sitio">
        <div class="col-sm-12 col-md-9">
            <div>
                <a class="lbl-noActive" href="/#/sa-rm/admin/config">Configuraciones > </a>
                <a href="/#/sa-rm/admin/config" class="lbl-noActive">&nbsp;ITEMS > </a>
                <a href="/#/sa-rm/items" class="lbl-noActive">&nbsp;LISTA > </a>
                <label class="lbl-active">&nbsp;Item {{obj.Nombre}}</label>
            </div>
            <h2>Item {{obj.Nombre}}</h2> 
        </div>
        <div class="col-sm-12">
            <a href="/#/sa-rm/items" class="float-left link-regresar" ><img src="../../../assets/IMG/arrow_back.png" /></a>
        </div>
      </div>
      <div class="wizard"></div>
      <div class="shadow p-3 mb-5 card rounded" style="background-color: rgb(255, 254, 254, .7) !important;">
          <form #itemForm="ngForm" autocomplete="off" appIdentityRevealed>
              <div class="row class">
                  <div class="col-4 offset-1">
                      <div class="form-group">
                          <label for="">NOMBRE DEL ITEM</label>
                          <input placeholder="Ingresa texto" #txtNombreCuest="ngModel"  [(ngModel)]="obj.Nombre" name="txtNombreCuest" type="text" id="txtNombreCuest" class="form-control" pattern="[^()/><\][\\\x22,;|]+" required>
                          <div *ngIf="txtNombreCuest.invalid"
                            class="lbl-error">
                            <span *ngIf="txtNombreCuest.errors.required">
                                El nombre es requerido
                            </span>
                            <span *ngIf="txtNombreCuest.errors.pattern">
                                El nombre tiene caracteres inválidos
                            </span>            
                          </div>
                      </div>
                      <div class="form-group">
                          <label for="">GRUPO</label>
                          <select name="" id="sltGroup" #sltGroup="ngModel" name="sltGroup" [(ngModel)]="obj.GrupoID" class="form-control" pattern="[^0].*">
                              <option value="0">Seleccionar...</option>
                              <option *ngFor="let item of grupos" [value]="item.ID">{{item.Nombre}}</option>
                          </select>
                          <div *ngIf="sltGroup.invalid"
                              class="lbl-error">
                              <span *ngIf="sltGroup.errors.required || sltGroup.errors.pattern">
                                  El grupo es requerido
                              </span>            
                          </div>
                      </div>
                      <div class="switch-form-base">
                           <label class="switch">
                               <input [(ngModel)]="obj.Multiple" type="checkbox" id="swtFormMultiple" name="swtFormMultiple">
                               <span class="slider round"></span>
                           </label>
                           &nbsp;
                           <label class="lblSwitch">MÚLTIPLE</label>
                      </div>
                      <div class="switch-form-base">
                           <label class="switch">
                               <input [(ngModel)]="obj.flagDefault" type="checkbox" id="swtFormDefault" name="swtFormDefault">
                               <span class="slider round"></span>
                           </label>
                           &nbsp;
                           <label class="lblSwitch">ITEM BASE</label>
                      </div>
                  </div>
                  <div class="col-5 offset-1">
                    <div class="form-group">
                        <label for="">NACIONAL/EXTRANJERA</label>
                        <select name="" id="NacionalExtranjera" #NacionalExtranjera="ngModel" name="NacionalExtranjera" [(ngModel)]="obj.NacionalExtranjera" class="form-control" pattern="[^0].*">
                            <option value="0" disabled>Seleccionar...</option>
                            <option value="Nacional">Nacional</option>
                            <option value="Extranjera">Extranjera</option>
                            <option value="Ambas">Ambas</option>
                        </select>
                        <div *ngIf="NacionalExtranjera.invalid"
                            class="lbl-error">
                            <span *ngIf="NacionalExtranjera.errors.required || NacionalExtranjera.errors.pattern">
                                El campo es requerido
                            </span>            
                        </div>
                    </div>
                      <div class="form-group">
                          <label for="">DESCRIPCIÓN</label>
                          <textarea placeholder="Ingresa texto" #txtDescripcion="ngModel" name="txtDescripcion" [(ngModel)]="obj.Descripcion" class="form-control" id="txtDescripcion" style="resize: none;" rows="6" required></textarea>
                          <div *ngIf="txtDescripcion.invalid"
                            class="lbl-error">
                            <span *ngIf="txtDescripcion.errors.required">
                                La descripción es requerida
                            </span>            
                          </div>
                      </div>
                  </div>
              </div> 
          </form>              
      </div>

      <div class="botones-wizard3 row">
        <div class="col-12">
            <button class="btn-layout pr-2 pl-2 float-right btnTableUp" (click)="saveItem()" [disabled]="itemForm.invalid">GUARDAR Y CONTINUAR</button>   
        </div>
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>