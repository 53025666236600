import { Component, OnInit } from '@angular/core';
import { AsistenciaService } from 'src/app/services/asistencia/asistencia.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-asistencia',
  templateUrl: './asistencia.component.html',
  styleUrls: ['./asistencia.component.css']
})
export class AsistenciaComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchAsistencia;
  public empresaID:number = 0;
  public catalog:any[];
  public asistencia:any = {};
  //Variables para Flujo Tickets
  public mdlTicket: boolean = false;
  public asistenciaREQ: any = {};

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:AsistenciaService,
    public router: Router,
  ) { 
    if(this.identity.empresa){
      this.empresaID = this.identity.empresa.EmpresaID
    }
  }

  ngOnInit(){
    this.getRegistros(1);
  }

  public getRegistros(status:number){
    this.loading.onLoadingRequest();
    this._service.getAsistenciaList(this.empresaID, status)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.catalog = response.content;
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public modalTicket(flag:boolean, id:number){
    if(flag){
      this.asistenciaREQ.AsistenciaID = id;
      this.asistenciaREQ.ReactivoItemEmpresaID = 0;
      this.asistenciaREQ.UsuarioID = this.identity.UsuarioID;
      this.asistenciaREQ.Comentario = "";

      this.loading.onLoadingRequest();
      this._service.getAsistencia(id)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          if(response.content != null){
            this.asistencia = response.content;
            console.log(this.asistencia);
            this.mdlTicket = flag;
          }else{
            Swal.fire("Sin registros","Aún no se han registrado tickets","info");      
          }
        }else if(response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        this.loading.onDoneRequest();
        console.log(error.toString());
      });
    }else{
      this.mdlTicket = flag;
    }
  }

  public crearTicket(status:number){
    this.asistenciaREQ.Status = status;
    this.loading.onLoadingRequest();
    this._service.updateAsistencia(this.asistenciaREQ)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status != 200){
        Swal.fire('Error', response.message, 'error');
      }else{
        Swal.fire('Éxito', response.message, 'success')
            .then(rs=>{
              this.mdlTicket = false;
            });
      }    
    }).catch(error=>{   
      this.loading.onDoneRequest();   
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    });
  }

  public openPreviewItem(id:number){
    this.router.navigate(['/sa-rm/itemreview/' + id]);
  }
}
