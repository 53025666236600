<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row">
            <div class="col-sm-12">
              <a (click)="regresar()" style="cursor: pointer;" class="float-left link-regresar"><img src="../../../assets/IMG/arrow_back.png" /></a>
            </div>
        </div>
        <div class="row mapa-sitio">
            <div class="col-9">
                <h2>{{item.Nombre}}</h2> 
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <div class="form-group">
                    <input type="text" [(ngModel)]="searchText" class="form-control buscador" placeholder="Buscador">
                </div>
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th  width="30%">Item</th>
                            <th  width="10%">Disponibilidad</th>
                            <th  width="10%">Fecha Asignación</th>
                            <th  width="10%">Reactivos Cargados</th>
                            <th  width="10%">% de Carga</th>
                            <th  width="10%">% de Validación</th>
                            <th  width="10%">Status</th>
                            <th  width="10%"></th>
                            <th  width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of cuestionario | catalog: searchText;">
                            <td width="5%">{{group.Nombre}}</td>
                            <td width="19%">
                                <input type="date" class="form-control"  [(ngModel)]="group.Disponibilidad" (change)="editarDisponibilidad(group.ItemEmpresaID)">
                            </td>
                            <td width="10%">{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                            <td width="20%">{{group.NumRespuestas}}</td>
                            <td width="8%">{{group.Resultado}}%</td>
                            <td width="8%">{{group.Calificacion}}%</td>
                            <td width="8%">{{group.NombreStatus}}</td>
                            <td width="10%">
                                <button (click)="openPreviewItem(group.ItemEmpresaID)" class="btn btn-layout btn-ch float-right btnTableUp">REVISAR</button>
                            </td>  
                            <td>
                                <button [hidden]="!identity.ClaimEliminarItem" class="btn btn-Eliminar btn-ch btnTableUp" (click)="delete(group.ItemEmpresaID)">Eliminar</button>
                            </td>       
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<div class="modal fade" id="mdlMotivo" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlMotivo ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">MOTIVO DE BAJA</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalMotivo(false,0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row principal">
                <div class="col-12">
                    <div class="form-group">
                        <label for="sltMotivo">Selecciona un motivo.</label>
                        <select name="sltMotivo" id="sltMotivo" #sltMotivo="ngModel" name="sltMotivo" [(ngModel)]="idMotivo" class="form-control">
                            <option value="0" disabled>Seleccionar...</option>
                            <option [value]="tl.Nombre" *ngFor="let tl of catalogTL">{{tl.Nombre}}</option>
                        </select>
                    </div>
                    <div class="form-group" *ngIf="idMotivo == 'Otro'">
                        <label>Especifica tu respuesta.</label>
                        <textarea [(ngModel)]="commentMotivo" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="div-btns">
                <button class="btn-layout btn-med btnTableUp mt-4 float-right ml-5" (click)="updateMotivo()">ENVIAR</button>    
            </div>    
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>