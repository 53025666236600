<div class="page-wrapper chiller-theme toggled">
  <a id="show-sidebar" class="btn btn-sm btn-dark" (click)="showsidebar()">
    <fa-icon [icon]="fasBars"></fa-icon>
  </a>
  <nav id="sidebar" class="sidebar-wrapper">
    <div class="sidebar-content">
      <div class="sidebar-brand">
        <a (click)="openContact(true)" style="cursor: pointer;">Powered by SA-RM</a>
        <div id="close-sidebar" (click)="closesidebar()">
          <fa-icon [icon]="fasTimes"></fa-icon>
        </div>
      </div>
      <div class="sidebar-header">
        <div class="user-pic">
          <img class="img-responsive img-rounded" src="../../../assets/IMG/userimg.png" alt="User picture">
        </div>
        <div class="user-info">
          <span class="user-name">
            <strong>{{identity.Nombre}}</strong>
          </span>
          <span class="user-role"><strong>{{identity.NombreRol}}</strong></span>
          <span class="user-status">
            <span class="onlinelbl pl-2 pr-2">Online</span>
          </span>
        </div>
      </div>
      <!-- sidebar-header  -->
      <div class="sidebar-menu">
        <ul>
          <li class="header-menu">
            <span>Menú</span>
          </li>
          <li *ngIf="Empresas" style="cursor: pointer;" (click)="active('noticias')">
              <a>
                <fa-icon [icon]="faNewspaper"></fa-icon>
                <span>     Noticias</span>
              </a>
          </li>
          <li *ngIf="Empresas" style="cursor: pointer;" (click)="active('client/false')">
              <a>
                <fa-icon [icon]="fasCircle"></fa-icon>
                <span>     Transportistas</span>
              </a>
          </li>
          <li *ngIf="identity.ClaimVerEliminados" style="cursor: pointer;" (click)="active('client/true')">
              <a>
                <fa-icon [icon]="fasCircle"></fa-icon>
                <span>     Transportistas Inactivos</span>
              </a>
          </li>
          <li *ngIf="Dashboard" style="cursor: pointer;" (click)="dashboard()">
              <a>
                <fa-icon [icon]="faChartArea"></fa-icon>
                <span>     Dashboard</span>
              </a>
          </li>
          <li *ngIf="Configuraciones" style="cursor: pointer;" (click)="active('reporte-admin')">
              <a>
                <fa-icon [icon]="faSortAlphaUp"></fa-icon>
                <span>     Reporte Administrador</span>
              </a>
          </li>
          <li *ngIf="Configuraciones" style="cursor: pointer;" (click)="active('reporte-ingreso')">
              <a>
                <fa-icon [icon]="faSortAlphaUp"></fa-icon>
                <span>     Reporte Ingreso</span>
              </a>
          </li>
          <li class="header-menu">
            <span>Administración</span>
          </li>
          <li *ngIf="Configuraciones" style="cursor: pointer;" (click)="active('config')">
              <a>
                <fa-icon [icon]="faCog"></fa-icon>
                <span>     Configuraciones</span>
              </a>
          </li>
          <li *ngIf="Empresas" style="cursor: pointer;" (click)="active('codes-qr')">
              <a>
                <fa-icon [icon]="faCog"></fa-icon>
                <span>     Códigos QR</span>
              </a>
          </li>
          <li style="cursor: pointer;" (click)="active('config-noticias')">
              <a>
                <fa-icon [icon]="faCog"></fa-icon>
                <span>     Noticias</span>
              </a>
          </li>
          <li class="header-menu">
            <span>Centro de Ayuda</span>
          </li>
          <li style="cursor: pointer;" (click)="asistencia()">
            <a>
              <fa-icon [icon]="faTicketAlt"></fa-icon>
              <span>     Tickets nuevos</span>
              <span class="badge badge-pill badge-primary">{{asistenciasNuevo}}</span>
            </a>
          </li>
          <li style="cursor: pointer;" (click)="asistencia()">
            <a>
              <fa-icon [icon]="faTicketAlt"></fa-icon>
              <span>     Tickets en curso</span>
              <span class="badge badge-pill badge-primary">{{asistenciasCurso}}</span>
            </a>
          </li>
          <li class="header-menu">
            <span>Alertas</span>
          </li>
          <li style="cursor: pointer;" (click)="openAlertas(5)">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Items pendientes</span>
              <span class="badge badge-pill badge-primary">{{itemsPendientes}}</span>
            </a>
          </li>
          <li style="cursor: pointer;" (click)="openAlertas(4)">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Reactivos pendientes</span>
              <span class="badge badge-pill badge-primary">{{reactivosPendientes}}</span>
            </a>
          </li>
          <li style="cursor: pointer;" (click)="openAlertas(2)">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Por Caducar</span>
              <span class="badge badge-pill badge-primary">{{porCaducar}}</span>
            </a>
          </li>
          <li style="cursor: pointer;" (click)="openAlertas(3)">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Caducados</span>
              <span class="badge badge-pill badge-primary">{{caducados}}</span>
            </a>
          </li>
          <li class="header-menu">
            <span>Alertas Contratos</span>
          </li>
          <li style="cursor: pointer;" (click)="openAlertaContrato()">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Pendientes</span>
              <span class="badge badge-pill badge-primary">{{alertaContrato}}</span>
            </a>
          </li>
          <li class="header-menu">
            <span>Alertas Tarifas</span>
          </li>
          <li style="cursor: pointer;" (click)="active('alerta-tarifa')">
            <a>
              <fa-icon [icon]="fasEnvelope"></fa-icon>
              <span>     Pendientes</span>
              <span class="badge badge-pill badge-primary">{{alertaTarifas}}</span>
            </a>
          </li>
          <br>
        </ul>
      </div>
      <!-- sidebar-menu  -->
    </div>
    <!-- sidebar-content  -->
  </nav>
  <!-- sidebar-wrapper  -->
  <div id="page-content-wrapper" class="page-content">
    
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- page-content" -->
</div>
<!-- page-wrapper -->


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlReport ? 'block' : 'none', 'opacity': 1}" style="overflow: auto;"> 
  <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
    <div class="modal-content">
      <div class="modal-header mdl-hdr text-center">
        <h5 class="modal-title w-100">Contáctanos</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openContact(false)">
          <span aria-hidden="true" class="mdl-close" >&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <form #createForm="ngForm" (ngSubmit)="enviarCorreo()" autocomplete="off" appIdentityRevealed method="POST" enctype="multipart/form-data">
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <div class="form-group">
                  <label for="Nombre">Nombre</label>
                  <input type="text" class="form-control" #Nombre="ngModel" name="Nombre" [(ngModel)]="obj.Nombre" placeholder="Ingresa texto"  required />
                  <div *ngIf="Nombre.invalid"
                      class="lbl-error">
                      <span *ngIf="Nombre.errors.required">
                          El campo es requerido
                      </span>            
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="form-group">
                  <label for="Empresa">Empresa</label>
                  <input type="text" class="form-control" #Empresa="ngModel" name="Empresa" [(ngModel)]="obj.Empresa" placeholder="Ingresa texto"  required />
                  <div *ngIf="Empresa.invalid"
                      class="lbl-error">
                      <span *ngIf="Empresa.errors.required">
                          El campo es requerido
                      </span>            
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="form-group">
                  <label for="correo">Correo</label>
                  <input type="text" class="form-control" #correo="ngModel" name="correo" [(ngModel)]="obj.Correo" placeholder="Ingresa texto" email required/>
                  <div *ngIf="correo.invalid" class="lbl-error">
                      <span *ngIf="correo.errors.required">
                          El campo es requerido
                      </span>
                      <span *ngIf="correo.errors.email">
                          Escriba un correo válido
                      </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div class="form-group">
                  <label for="telefono">Teléfono</label>
                  <input type="number" class="form-control" #telefono="ngModel" name="telefono" [(ngModel)]="obj.telefono" placeholder="Ingresa teléfono"/>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="Descripcion">Comentarios</label>
                  <textarea placeholder="Ingresa texto" #Descripcion="ngModel" name="Descripcion" [(ngModel)]="obj.Descripcion" class="form-control" id="Descripcion" style="resize: none;" rows="3"></textarea>
                </div>
              </div>
            </div>
              <div class="div-btns row pt-3">   
                <div class="col-6">
                  <button class="btn btn-fluid btn-light" (click)="openContact(false)" data-dismiss="modal" type="button">CANCELAR</button>            
                </div>
                <div class="col-6">     
                  <button class="btn btn-fluid btn-dark" [disabled]="createForm.invalid">ENVIAR CORREO</button>    
                </div>
              </div>
          </form>            
      </div>       
    </div>
  </div>
</div>