import { Component, OnInit } from '@angular/core';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchClient;
  public catalog:any[];
  public catalogComplete:any[];
  public texto:string = "";
  public flagExtranjera:boolean = false;

  identity = JSON.parse(localStorage.getItem('identity_user'));
  public deleted = this._route.snapshot.paramMap.get('deleted');
  claimEdit = false;

  constructor(
    private _service:ClientesService,
    public router: Router,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(){
    this.verifyClaims();
    this._route.params.subscribe(
        params => {
            this.deleted = params['deleted'];
            if(this.deleted == "true"){
              this.texto = "Eliminadas";
              this.getRegistrosDeleted();
            }else{
              this.texto = "";
              this.getRegistros();
            }
        }
    );
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    this.claimEdit = this.identity.permisoLists.find(x => x.nombrePermiso === 'Editar Empresas') ? true : false;
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getCliente(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogComplete = response.content;
          this.catalog = response.content.filter(x=> x.LineaExtranjera == this.flagExtranjera);
        }else{
          this.catalog = [];
          Swal.fire("Sin registros","Aún no se han registrado empresas","info");      
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getRegistrosDeleted(){
    this.loading.onLoadingRequest();
    this._service.getClienteEliminado(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalogComplete = response.content;
          this.catalog = response.content.filter(x=> x.LineaExtranjera == this.flagExtranjera);
        }else{
          this.catalog = [];
          Swal.fire("Sin registros","Aún no se han registrado empresas","info");      
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public filterExtranjeras(){
    this.catalog = this.catalogComplete.filter(x=> x.LineaExtranjera == this.flagExtranjera);
  }
}
