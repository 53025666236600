<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-12">
                <h2>Empresas {{texto}}</h2> 
            </div>
            <div class="col-12 mt-3">
                <label class="switch">
                    <input [(ngModel)]="flagExtranjera" (change)="filterExtranjeras()" type="checkbox">
                    <span class="slider round"></span>
                </label>
                &nbsp;
                <label class="lblSwitch" style="color: white;">LTs Extranjeras</label>
            </div>
        </div>
        <div class="row principal">
            <div class="col-sm-7 col-md-8 col-lg-9">
                <input type="text" [(ngModel)]="searchClient" class="buscador form-control"  placeholder="Buscador">
            </div>
            <div class="col-sm-5 col-md-4 col-lg-3">
                <button class='btn-layout btn-fluid float-right btnTableUp' [hidden]="!claimEdit || deleted == 'true'" type='button' style='margin-left:10px;' routerLink="/sa-rm/nuevocliente">
                    CREAR NUEVO
                </button>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                        <tr>
                            <th width="10%">NUM PROVEEDOR</th>
                            <th width="30%">NOMBRE EMPRESA</th>
                            <th width="30%">NOMBRE COMERCIAL</th>
                            <th width="10%">TIPO PERSONA</th>
                            <th width="10%">PROVEEDOR</th>
                            <th width="10%" [hidden]="deleted != 'true'">MOTIVO INHABILITACIÓN</th>
                            <th width="10%">ITEM A</th>
                            <th width="10%">ITEM V</th>
                            <th width="20%">FECHA DE CREACIÓN</th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of catalog | filterclientlist: searchClient; ">
                            <td>{{group.NumProveedor}}</td>
                            <td>{{group.Nombre}}</td>
                            <td>{{group.NombreComercial}}</td>
                            <td>{{group.NombreTipoPersona}}</td>
                            <td>{{group.ProveedorVal}}</td>
                            <td [hidden]="deleted != 'true'">{{group.MotivoInhabil}}</td>
                            <td>{{group.itemA}}</td>
                            <td>{{group.itemV}}</td>
                            <td>{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                            <td>
                                <button class="btn btn-layout btn-ch btnTableUp" routerLink="/sa-rm/admin-client/{{group.EmpresaID}}/ft/{{group.EmpresaID}}">VER</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>             
        </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>