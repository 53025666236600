import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Highcharts from 'highcharts';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { AuthService } from 'src/app/services/login/auth/auth.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let Solid = require('highcharts/modules/solid-gauge');
let Exporting = require('highcharts/modules/exporting');
let Export = require('highcharts/modules/export-data');
let Accessibility = require('highcharts/modules/accessibility');
let Drilldown = require('highcharts/modules/drilldown');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
Solid(Highcharts);
Exporting(Highcharts);
Export(Highcharts);
Accessibility(Highcharts);
Drilldown(Highcharts);

@Component({
  selector: 'app-dashboardco',
  templateUrl: './dashboardco.component.html',
  styleUrls: ['./dashboardco.component.css']
})
export class DashboardcoComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public flagEXT = this._route.snapshot.paramMap.get('id');
  public obj:any = {
    DG: {
      PERTOTAL:0
    }
  };
  series:any;
  renderer:any;
  chartWidth:any;
  plotHeight:any;

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:DashboardService,
    private _auth: AuthService,
    private _serviceUsuario:UsuariosService,
    private _route: ActivatedRoute,
    private _router: Router
  ) { }

  ngOnInit(){
    // get html body element
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.add("body-dash");
    }
    this._route.params.subscribe(
        params => {
            this.flagEXT = params['id'];
            this.verifyClaims();
            this.getRegistros();
            this.loading.onDoneRequest();
        }
    );
  }

  ngOnDestroy() {
    // remove the class form body tag
    const bodyElement = document.body;
    if (bodyElement) {
      bodyElement.classList.remove("body-dash");
    }
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Dashboard');
    if(claim == undefined && claim == null){
      this._router.navigate(['/sa-rm/admin']);
    }
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getDashboardGeneral(this.identity.NombreRol, 0, this.flagEXT)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.obj = response
        const factor = 10 ** 2;
        this.obj.DG.PEROperaciones = Math.round(this.obj.DG.PEROperaciones * factor)/ factor;
        this.obj.DG.PERContrato = Math.round(this.obj.DG.PERContrato * factor)/ factor;
        this.obj.DG.PERDeseable = Math.round(this.obj.DG.PERDeseable * factor)/ factor;
        this.obj.DG.PERTOTAL = Math.round(this.obj.DG.PERTOTAL * factor)/ factor;
        console.log(response);
        this.graph();
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public graph(){
    var opciones:any = {
      chart: {
          type: 'solidgauge',
          height: '110%',
          backgroundColor: 'transparent'
      },
      title: {
        text: ''
      },
      tooltip: {
          borderWidth: 0,
          backgroundColor: 'none',
          shadow: false,
          style: {
              fontSize: '16px',
              color: 'black'
          },
          valueSuffix: '%',
          pointFormat: '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',
          positioner: function (labelWidth) {
              return {
                  x: (this.chart.chartWidth - labelWidth) / 2,
                  y: (this.chart.plotHeight / 2) - 25
              };
          }
      },
      pane: {
          startAngle: 0,
          endAngle: 360,
          background: [{ // Track for Move
              outerRadius: '112%',
              innerRadius: '88%',
              backgroundColor: Highcharts.color(Highcharts.getOptions().colors[3])
                  .setOpacity(0.3)
                  .get(),
              borderWidth: 0
          }, { // Track for Exercise
              outerRadius: '87%',
              innerRadius: '63%',
              backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
                  .setOpacity(0.3)
                  .get(),
              borderWidth: 0
          }, { // Track for Stand
              outerRadius: '62%',
              innerRadius: '38%',
              backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
                  .setOpacity(0.3)
                  .get(),
              borderWidth: 0
          }]
      },
      yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: []
      },
      plotOptions: {
          solidgauge: {
              dataLabels: {
                  enabled: false
              },
              linecap: 'round',
              stickyTracking: false,
              rounded: true
          }
      },
      credits:{
        enabled: false
      },
      series: [{
          name: 'Mandatorios para contrato',
          data: [{
              color: Highcharts.getOptions().colors[3],
              radius: '112%',
              innerRadius: '88%',
              y: this.obj.DG.PERContrato
          }]
      }, {
          name: 'Mandatorios para visita de certificación',
          data: [{
              color: Highcharts.getOptions().colors[1],
              radius: '87%',
              innerRadius: '63%',
              y: this.obj.DG.PEROperaciones
          }]
      }, {
          name: 'No mandatorios',
          data: [{
              color: Highcharts.getOptions().colors[2],
              radius: '62%',
              innerRadius: '38%',
              y: this.obj.DG.PERDeseable
          }]
      }]
    }

    Highcharts.chart('graphCo', opciones);
  }

  gotoDashboard(tipo:number){
    if(tipo == 1){
      this._router.navigate(['/sa-rm/admin/dashboard-area/' + this.flagEXT]);
    }else{
      this._router.navigate(['/sa-rm/admin/dashboard-empresa/' + this.obj.rolcheck + ',' + this.flagEXT]);
    }
  }
}
