import { Component, OnInit } from '@angular/core';
import { ItemService } from 'src/app/services/items/item.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();
  searchText;
  public objPreview:any = {};
  public mdlPreview:boolean = false;
  public catalog:any[];

  constructor(
    private _service:ItemService,
    private router:Router
  ) { }

  ngOnInit() {
    this.verifyClaims();
    this.getItem();
    this.loading.onDoneRequest();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
    this.identity.permisoLists
  }
  
  public getItem(){    
    this.loading.onLoadingRequest();
    this._service.getItem(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content.map((x) =>
          {
            const factor = 10 ** 2;
            x.Calificacion = Math.round(x.Calificacion * factor)/ factor;
            return x
          });
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  delete(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el item?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._service.deleteItem(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
          
            this.getItem();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }

  public update(id: number){
    this.router.navigate(['/sa-rm/updateitem/' + id.toString()]);
  }

  public preview(id: number){
    if(id === 0){
      this.mdlPreview = false;
    }else{
      let act = this.catalog.find(x => x.ItemID === id);
      this.objPreview = {};
      this.objPreview.ItemID = act.ItemID;
      this.objPreview.Nombre = act.Nombre;
      this.objPreview.Descripcion = act.Descripcion;
      
      this.mdlPreview = true;
    }
  }

  public newVersion(id: number){
    this.loading.onLoadingRequest();
    this._service.newVersion(id)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        this.router.navigate(['/sa-rm/updateitem/' + response.message.toString()]);
      }else if (response.status == 500){      
        Swal.fire('Error', response.message, 'error');
      }else {
        Swal.fire("Atención",response.message,"info");
      }
    }).catch(error=>{ 
      this.loading.onDoneRequest();     
      Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
    })
  }
}
