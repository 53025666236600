import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { AngularEditorModule } from '@kolkov/angular-editor';

import {DataTablesModule} from 'angular-datatables';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { AppRouting } from './routes/routing';
import { HeaderComponent } from './Layout/header/header.component';
import { SidebarCoComponent } from './Layout/sidebar-co/sidebar-co.component';
import { SidebarClComponent } from './Layout/sidebar-cl/sidebar-cl.component';
import { SidebarCoclComponent } from './Layout/sidebar-cocl/sidebar-cocl.component';
import { ConfigComponent } from './Layout/sidebar-co/config/config.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './Layout/footer/footer.component';
import { NivelComponent } from './components/Catalogos/nivel/nivel.component';
import { PracticaComponent } from './components/Catalogos/practica/practica.component';
import { LoginconsultorComponent } from './components/loginconsultor/loginconsultor.component';
import { FiltercatalogoPipe } from './pipes/filter/filtercatalogo.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconPickerModule } from 'ngx-icon-picker';
import { ClientComponent } from './Layout/sidebar-co/client/client.component';
import { FilterclientlistPipe } from './pipes/filter/client/filterclientlist.pipe';
import { FtComponent } from './Layout/sidebar-cocl/ft/ft.component';
import { NuevoClientComponent } from './components/nuevo-client/nuevo-client.component';

import {IvyCarouselModule} from 'angular-responsive-carousel';
import { DashboardComponent } from './Layout/sidebar-cl/dashboard/dashboard.component';
import { DashboardcoComponent } from './Layout/sidebar-co/dashboardco/dashboardco.component';
import { LoadingEfectComponent } from './Layout/loading-efect/loading-efect.component';
import { RolesComponent } from './components/roles/roles.component';
import { RolePipe } from './pipes/filter/role/role.pipe';
import { CatalogPipe } from './pipes/filter/catalog/catalog.pipe';
import { ItemPipe } from './pipes/filter/item/item.pipe';
import { ItemsComponent } from './components/items/items.component';
import { UpdateitemComponent } from './components/items/updateitem/updateitem.component';
import { Updateitem2Component } from './components/items/updateitem2/updateitem2.component';
import { ReactivoPipe } from './pipes/filter/reactivo/reactivo.pipe';
import { PreviewComponent } from './components/items/item-preview/preview.component';
import { ItemReviewComponent } from './components/items/item-review/item-review.component';
import { ItemSublistComponent } from './Layout/sidebar-cl/dashboard/item-sublist/item-sublist.component';
import { ItemSublistCoclComponent } from './Layout/sidebar-cocl/ft/item-sublist-cocl/item-sublist-cocl.component';
import { AsistenciaComponent } from './components/asistencia/asistencia.component';
import { AsistenciaPipe } from './pipes/filter/asistencia/asistencia.pipe';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GeneralComponent } from './components/Catalogos/general/general.component';
import { CatalogoComponent } from './components/Catalogos/general/catalogo/catalogo.component';
import { AlertasComponent } from './Layout/sidebar-cl/alertas/alertas.component';
import { ItemlistCoComponent } from './Layout/sidebar-co/alerta-co/itemlist-co/itemlist-co.component';
import { AlertaCoComponent } from './Layout/sidebar-co/alerta-co/alerta-co.component';
import { ReactivoClComponent } from './Layout/sidebar-cl/alertas/reactivo-cl/reactivo-cl.component';
import { ReactivoCoComponent } from './Layout/sidebar-co/alerta-co/itemlist-co/reactivo-co/reactivo-co.component';
import { DashboardAreaComponent } from './Layout/sidebar-co/dashboardco/dashboard-area/dashboard-area.component';
import { DashboardEmpresaComponent } from './Layout/sidebar-co/dashboardco/dashboard-empresa/dashboard-empresa.component';
import { DetalleComponent } from './Layout/sidebar-co/dashboardco/dashboard-empresa/detalle/detalle.component';
import { DashboardEmpresaPipe } from './pipes/filter/dashboard-empresa/dashboard-empresa.pipe';
import { TipoContratoComponent } from './components/Catalogos/tipo-contrato/tipo-contrato.component';
import { ContratoComponent } from './components/contrato/contrato.component';
import { AlertaContratoComponent } from './components/contrato/alerta-contrato/alerta-contrato.component';
import { AlertaContratoPipe } from './pipes/filter/alerta-contrato/alerta-contrato.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ResumenComponent } from './Layout/sidebar-co/dashboardco/dashboard-empresa/resumen/resumen.component';
import { ReporteAdminComponent } from './components/reporte-admin/reporte-admin.component';
import { ReporteAdminPipe } from './pipes/filter/reporte-admin/reporte-admin.pipe';
import { ReporteIngresoComponent } from './components/reporte-ingreso/reporte-ingreso.component';
import { ReporteIngresoPipe } from './pipes/filter/reporte-ingreso/reporte-ingreso.pipe';
import { TarifasComponent } from './Layout/sidebar-cocl/ft/tarifas/tarifas.component';
import { TarifasPipe } from './pipes/filter/tarifas/tarifas.pipe';
import { AlertasTarifasComponent } from './Layout/sidebar-co/alertas-tarifas/alertas-tarifas.component';
import { TarifasTransportistaComponent } from './Layout/sidebar-cl/tarifas-transportista/tarifas-transportista.component';
import { TarifasAlertasPipe } from './pipes/filter/tarifasAlertas/tarifas-alertas.pipe';
import { ModuloQRComponent } from './components/modulo-qr/modulo-qr.component';
import { ClientQrComponent } from './Layout/sidebar-cocl/ft/client-qr/client-qr.component';
import { InfoQRComponent } from './components/info-qr/info-qr.component';
import { InfomultipleQRComponent } from './components/infomultiple-qr/infomultiple-qr.component';
import { ConfigNoticiasComponent } from './Layout/sidebar-co/config-noticias/config-noticias.component';
import { NoticiasComponent } from './Layout/sidebar-co/noticias/noticias.component';
import { UpdateNoticiasComponent } from './Layout/sidebar-co/config-noticias/update-noticias/update-noticias.component';
import { DndDirective } from './directives/dnd.directive';
import { NoticiasPipe } from './pipes/filter/noticias/noticias.pipe';

@NgModule({
  declarations: [
    AppComponent,
    UsuariosComponent,
    HeaderComponent,
    SidebarCoComponent,
    SidebarClComponent,
    SidebarCoclComponent,
    ConfigComponent,
    FooterComponent,
    NivelComponent,
    PracticaComponent,
    LoginconsultorComponent,   
    FiltercatalogoPipe,
    ClientComponent,
    FilterclientlistPipe,
    FtComponent,
    NuevoClientComponent,
    DashboardComponent,
    DashboardcoComponent,
    LoadingEfectComponent,
    RolesComponent,
    RolePipe,
    CatalogPipe,
    ItemPipe,
    ItemsComponent,
    UpdateitemComponent,
    Updateitem2Component,
    ReactivoPipe,
    PreviewComponent,
    ItemReviewComponent,
    ItemSublistComponent,
    ItemSublistCoclComponent,
    AsistenciaComponent,
    AsistenciaPipe,
    GeneralComponent,
    CatalogoComponent,
    AlertasComponent,
    ItemlistCoComponent,
    AlertaCoComponent,
    ReactivoClComponent,
    ReactivoCoComponent,
    DashboardAreaComponent,
    DashboardEmpresaComponent,
    DetalleComponent,
    DashboardEmpresaPipe,
    TipoContratoComponent,
    ContratoComponent,
    AlertaContratoComponent,
    AlertaContratoPipe,
    ResumenComponent,
    ReporteAdminComponent,
    ReporteAdminPipe,
    ReporteIngresoComponent,
    ReporteIngresoPipe,
    TarifasComponent,
    TarifasPipe,
    AlertasTarifasComponent,
    TarifasTransportistaComponent,
    TarifasAlertasPipe,
    ModuloQRComponent,
    ClientQrComponent,
    InfoQRComponent,
    InfomultipleQRComponent,
    ConfigNoticiasComponent,
    NoticiasComponent,
    UpdateNoticiasComponent,
    DndDirective,
    NoticiasPipe
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FontAwesomeModule,
    DataTablesModule,
    HttpModule,   
    FormsModule,
    AngularEditorModule,
    NgxQRCodeModule,
    AppRouting,
    BrowserAnimationsModule,
    IconPickerModule,
    IvyCarouselModule,
    NgbModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
