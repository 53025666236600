<div id="site_wrapper">
    <div class="contenedor-principal">
        <div class="row mapa-sitio">
            <div class="col-9">
                <div>
                    <a class="lbl-noActive" href="/#/sa-rm/admin/client/false">Clientes > </a><a href="/#/sa-rm/admin-client/{{id}}/ft/{{id}}" class="lbl-noActive">&nbsp;{{client.Nombre}} > </a><label class="lbl-active">&nbsp;Ficha Técnica</label>
                </div>
                <h2>{{client.Nombre}}</h2> 
                <div class="row mt-3 mb-3">
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-lg-6 col-xl-3 mb-3">
                                <label class="switch">
                                    <input [(ngModel)]="client.Proveedor" type="checkbox" [disabled]="!editarData">
                                    <span class="slider round"></span>
                                </label>
                                &nbsp;
                                <label class="lblSwitch">Proveedor</label>
                            </div>
                            <div class="col-lg-6 col-xl-3 mb-3">
                                <label class="switch">
                                    <input [(ngModel)]="client.Eliminado" type="checkbox" [disabled]="!editarData">
                                    <span class="slider round"></span>
                                </label>
                                &nbsp;
                                <label class="lblSwitch">Inhabilitar</label>
                            </div>
                            <div class="col-lg-12 col-xl-6 mb-3">
                                <button *ngIf="client.TipoContrato != 0 && !client.Proveedor" class="btn-layout btnTableUp ml-2 mb-2" (click)="goRoute('contrato')">
                                    Status Contrato
                                </button>
                                <button class='btn-Eliminar btn-ch btnTableUp ml-2 mb-2' (click)="goRoute('tarifa')">
                                    TARIFAS
                                </button>
                                <button class='btn-Eliminar btn-ch btnTableUp ml-2 mb-2' (click)="goRoute('client-qr')">
                                    Códigos QR
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <form #createForm="ngForm" autocomplete="off" appIdentityRevealed>
            <div class="row editar-empresa">
                <div class="col-sm-12 col-md-6">
                    <div class="graph-title">DATOS EMPRESA
                        <button class='btn-Eliminar btn-ch btnTableUp ml-2' [hidden]="editarData || !claimEdit" type='button' (click)='open()'>
                            EDITAR
                        </button>
                        <button class='btn-Eliminar btn-ch btnTableUp ml-2' [disabled]="createForm.invalid" [hidden]="!editarData" (click)='update()'>
                            GUARDAR
                        </button>
                    </div>
                    <div class="row graphs-client ml-0 mr-0 pt-2 pb-2">
                        <div class="col-12">
                            <div class="form-group" [hidden]="!client.Eliminado">
                                <label for="MotivoInhabil">MOTIVO INHABILITACIÓN</label>
                                <select name="MotivoInhabil" id="MotivoInhabil" #MotivoInhabil="ngModel" name="MotivoInhabil" [(ngModel)]="client.MotivoInhabil" class="form-control" [disabled]="!editarData">
                                    <option value="ADMINISTRATIVA">ADMINISTRATIVA</option>
                                    <option value="OPERATIVA">OPERATIVA</option>
                                    <option value="BAJA">BAJA</option>
                                </select>
                            </div>
                            <div class="form-group mb-0">
                                <label for="NumProveedor">NUM. PROVEEDOR</label>
                                <a class="lbl-noActive ml-2" style="cursor: pointer;" (click)="agregarSPN()" [hidden]="!editarData">+Agregar</a>
                            </div>
                            <div class="row" style="margin-bottom: 1rem;" *ngFor="let numSPN of spn; index as i;">
                                <div class="col-8">
                                    <input type="text" class="form-control" name="NumProveedor-{{i}}" [(ngModel)]="spnIndex[i]" [disabled]="!editarData">
                                </div>
                                <div class="col-4 pl-0 pt-1" [hidden]="!editarData">
                                    <button class='btn-Eliminar btn-ch btnTableUp ml-2' type='button' style='margin-top: -3px !important; margin-bottom: -3px !important;' (click)="deleteSPN(i)">
                                        ELIMINAR
                                    </button>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="Nombre">NOMBRE EMPRESA</label>
                                <input type="text" class="form-control" id="Nombre" #Nombre="ngModel" name="Nombre" [(ngModel)]="client.Nombre" required [disabled]="!editarData">
                                <div *ngIf="Nombre.invalid" class="lbl-error">
                                    <span *ngIf="Nombre.errors.required">
                                        El campo es requerido
                                    </span>            
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="NombreComercial">NOMBRE COMERCIAL</label>
                                <input type="text" class="form-control" id="NombreComercial" #NombreComercial="ngModel" name="NombreComercial" [(ngModel)]="client.NombreComercial" [disabled]="!editarData">
                            </div>
                            <div class="form-group">
                                <label for="TipoPersona">TIPO PERSONA</label>
                                <select name="TipoPersona" id="TipoPersona" #TipoPersona="ngModel" name="TipoPersona" [(ngModel)]="client.TipoPersona" class="form-control" required pattern="[^0].*" [disabled]="!editarData">
                                    <option value="0" disabled>Seleccionar...</option>
                                    <option value="1">Moral</option>
                                    <option value="2">Física</option>
                                    <option value="3">Ambas</option>
                                </select>
                                <div *ngIf="TipoPersona.invalid"
                                    class="lbl-error">
                                    <span *ngIf="TipoPersona.errors.required || TipoPersona.errors.pattern">
                                        El grupo es requerido
                                    </span>            
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="TipoLinea">TIPO DE LINEA</label>
                                <ng-select id="TipoLinea" 
                                        #TipoLinea="ngModel" 
                                        name="TipoLinea" 
                                        placeholder="Seleccionar..."                                
                                        multiple="true"
                                        [(ngModel)]="tipoLinea" required [disabled]="!editarData">
                                    <ng-option [value]="tl.ID" *ngFor="let tl of catalogTL">
                                        {{tl.Nombre}}
                                    </ng-option>
                                </ng-select>
                                <div *ngIf="TipoLinea.invalid"
                                    class="lbl-error">
                                    <span *ngIf="TipoLinea.errors.required">
                                        El tipo de linea es requerido
                                    </span>            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="graph-title">CONTACTOS
                        <button class='btn-Eliminar btn-ch btnTableUp ml-2' [hidden]="!claimEdit" type='button' (click)="openNewContact(true)">
                            AGREGAR
                        </button>
                    </div>
                    <div class="row graphs-client ml-0 mr-0 pt-2 pb-2">
                        <table class="table table-responsive" style="height: auto!important;">
                            <tbody style="background-color: transparent!important;">
                                <TR *ngFor="let group of client.users">
                                    <td width="80%">
                                        <input type="text" class="form-control" [value]="group" disabled>
                                    </td>
                                    <td width="20%" [hidden]="!claimEdit">
                                        <button class='btn-Eliminar btn-ch btnTableUp ml-2' type='button' style='margin-top: -3px !important; margin-bottom: -3px !important;' (click)="deleteContact(group)">
                                            ELIMINAR
                                        </button>
                                    </td>
                                </TR>
                            </tbody>
                        </table>
                        <div *ngIf="claimDash" class="col-sm-3 col-md-12 col-lg-3" style="height: fit-content;">
                            <label class="lbl-filtros dash-title">GENERAL</label><br>
                            <label class="lbl-filtros dash-number">{{objGraph.DG.PERTOTAL}}%</label><br>
                        </div>
                        <div *ngIf="claimDash" class="col-sm-9 col-md-12 col-lg-9">
                            <div id="graph" class="" style="height: 190px;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row principal">
            <div class="col-sm-12">
                <span style="font-weight: bold;">Items asignados</span>
            </div>
        </div>
        <div class="row tabla-catalogo">  
            <div class="col-12">
                <table class="table table-responsive"> 
                    <thead>
                    <tr>
                        <th  width="100%">Item</th>
                        <th  width="10%">Num.</th>
                        <th  width="20%">Resultado</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let group of cuestionario" (click)="openPreviewItem(group.ItemID)" style="cursor: pointer;">
                            <td width="90%">{{group.Nombre}}</td>
                            <td width="10%">{{group.NumRespuestas}}</td>
                            <td width="20%">{{group.Resultado}}%</td>
                        </tr>
                    </tbody>
                </table>
                <a class="lbl-noActive btn" (click)="agregarItem(true)">+Agregar Item</a>
            </div>             
        </div>
    </div>
    
</div>

<div class="modal fade" id="edicionModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': editarNC ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openNewContact(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #editForm="ngForm" (ngSubmit)="newContact()" autocomplete="off" appIdentityRevealed method="POST" enctype="multipart/form-data">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="Correo">CORREO ELECTRÓNICO</label>
                  <input type="email" class="form-control" #Correo="ngModel" name="Correo" [(ngModel)]="obj.Correo" placeholder="Ingresa texto"  required email/>
                  <div *ngIf="Correo.invalid"
                      class="lbl-error">
                      <span *ngIf="Correo.errors.required">
                          El correo es requerido
                      </span>
                      <span *ngIf="Correo.errors.email">
                        El correo no es válido
                    </span>      
                  </div>
                </div>
              </div>
            </div>
            <div class="div-btns row pt-3">
              <div class="col-6">
                <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="openNewContact(false)" data-dismiss="modal" type="button">CANCELAR</button>            
              </div>
              <div class="col-6">    
                <button class="btn btn-fluid btn-layout btnTableUp" [disabled]="editForm.invalid">GUARDAR</button>   
              </div> 
            </div>
          </form>               
        </div>       
      </div>
    </div>
  </div>

  <div class="modal fade" id="mdlItemList" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdlItemList ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-xl modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100">AGREGAR ITEM</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="agregarItem(false)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row principal">
                <div class="col-sm-12">
                    <input type="text" [(ngModel)]="searchText" class="buscador form-control" placeholder="Buscador">
                </div>
            </div>
            <div class="row tabla-catalogo">  
                <div class="col-12">
                    <table class="table table-responsive"> 
                        <thead>
                        <tr>
                            <th width="40%">NOMBRE ITEM</th>
                            <th width="10%">GRUPO</th>
                            <th width="10%">DEFAULT</th>
                            <th width="10%">NUM. REACTIVOS</th>
                            <th width="10%">PUNTOS TOTALES</th>
                            <th width="20%">FECHA DE CREACIÓN</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let group of catalog | item: searchText; ">
                            <td>
                                <input type="checkbox" id="ckb-{{group.ItemID}}">
                                {{group.Nombre}}
                            </td>
                            <td>{{group.NombreGrupo}}</td>
                            <td>{{group.Default}}</td>
                            <td>{{group.totalPreguntas}}</td>
                            <td>{{group.Calificacion}}</td>
                            <td>{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>             
            </div>
            <div class="div-btns">
                <button class="btn btn-layout btnTableUp float-right ml-5 pl-5 pr-5" (click)="guardarItem()">AGREGAR</button>    
            </div>    
        </div>       
      </div>
    </div>
  </div>

  <app-loading-efect></app-loading-efect>