import { Component, OnInit } from '@angular/core';
import { ItemService } from 'src/app/services/items/item.service';
import { CodigoQRService } from 'src/app/services/codigoQR/codigo-qr.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';

@Component({
  selector: 'app-modulo-qr',
  templateUrl: './modulo-qr.component.html',
  styleUrls: ['./modulo-qr.component.css']
})
export class ModuloQRComponent implements OnInit {
  identity = JSON.parse(localStorage.getItem('identity_user'));
  loading = new LoadingEfectComponent();
  searchText;
  public objPreview:any = {};
  public obj:any = {};
  public mdlPreview:boolean = false;
  public catalog:any[];
  public codes:any[];
  public modalQR:boolean = false;
  public item:any[] = [];
  public itemIndex:any[] = [];

  constructor(
    private _service:ItemService,
    private _serviceCodigoQR:CodigoQRService,
    private router:Router
  ) { }

  ngOnInit() {
    this.verifyClaims();
    this.getItem();
    this.getCodes();
    this.loading.onDoneRequest();
  }

  verifyClaims(){
    var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    if(claim == undefined && claim == null){
      this.router.navigate(['/sa-rm/admin']);
    }
    this.identity.permisoLists
  }
  
  public getCodes(){    
    this.loading.onLoadingRequest();
    this._serviceCodigoQR.getCodigosqr()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.codes = response.content;
        }
        else{
          Swal.fire("Atención","No hay datos que mostrar.","info");
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getItem(){    
    this.loading.onLoadingRequest();
    this._service.getItem(0)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content.map((x) =>
          {
            const factor = 10 ** 2;
            x.Calificacion = Math.round(x.Calificacion * factor)/ factor;
            return x
          });
        }
        else{
          Swal.fire("Atención","No hay items que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getReactivos(id:number){ 
    this.loading.onLoadingRequest();
    this._service.getReactivosbyItem(this.itemIndex[id].ItemID)
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.itemIndex[id].React = response.content;
        }
        else{
          Swal.fire("Atención","No hay reactivos que mostrar.","info");
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getReactivosResp(id:number){ 
    this._service.getReactivosbyItem(this.itemIndex[id].ItemID)
    .then(response=>{
      this.itemIndex[id].React = response.content;
    });
  }

  delete(id: number){
    Swal.fire({
      title: 'Atención',
      text: "¿Estas seguro que deseas eliminar el código?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading.onLoadingRequest();
        this._serviceCodigoQR.deleteCodigoqr(id)
        .then(response=>{
          this.loading.onDoneRequest();
          if(response.status == 200){
            Swal.fire('Éxito', response.message, 'success');
            this.getCodes();
          }else{
            Swal.fire('Error', response.message, 'error');
          }
        }).catch(error=>{  
          this.loading.onDoneRequest();    
          Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
        })
      }
    })
  }
  
  public openModalQR(flag:boolean, id:number){
    this.obj.CodigoQRID = id;
    if(id != 0){
      $(".modal-title").html("Editar Código QR");
      let catAct = this.codes.find(cat => cat.CodigoQRID === id);
      console.log(catAct)
      this.obj.Nombre = catAct.Nombre;
      this.obj.Descripcion = catAct.Descripcion;
      this.obj.Password = catAct.Password;
      this.obj.Multiple = catAct.Multiple;
      this.item = [];
      this.itemIndex = [];
      let index = 0;
      catAct.CodigoQRItems.forEach(element => {
        this.item.push("");
        this.itemIndex.push({
          CodigoQRItemID: element.CodigoQRItemID,
          ItemID: element.ItemID,
          Reactivos: element.Reactivos.split(",").map( Number ),
          React: []
        });
        this.getReactivosResp(index);
        index ++;
      });
    }else{
      this.obj.Nombre = "";
      this.obj.Descripcion = "";
      this.obj.Password = "";
      this.obj.Multiple = false;
      this.item = [1];
      this.itemIndex = [{
        CodigoQRItemID:0,
        ItemID:null,
        Reactivos:"",
        React:[]
      }];
      $(".modal-title").html("Crear nuevo Código QR");
    }
    this.modalQR = flag;
  }

  public agregarItem(){
    this.item.push("");
    this.itemIndex.push({
      CodigoQRItemID:0,
      ItemID:null,
      Reactivos:"",
      React:[]
    });
  }

  public deleteItem(index){
    this.item.splice(index, 1);
    this.itemIndex.splice(index, 1);
  }

  public update(){
    console.log(this.itemIndex)
    let ind = this.itemIndex.find(x=> !x.Reactivos.length || x.Reactivos.length == 0);
    if(ind){
      Swal.fire('Atención', 'Debes seleccionar al menos un reactivo por item agregado.', 'warning');
    }else{
      let codigo = this.obj;
      let items = JSON.parse(JSON.stringify(this.itemIndex));
      codigo.CodigoQRItems = items.map((x) =>
      {
        x.Reactivos = x.Reactivos.toString();
        return x
      });
      this.loading.onLoadingRequest();
      this._serviceCodigoQR.updateCodigoqr(codigo)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          this.getCodes();
          this.openModalQR(false,0);
          Swal.fire("Éxito",response.message,"success");
        }else if(response.status == 500){
          Swal.fire("Error",response.message,"error");
        }
      })
      .catch(error=>{
        this.loading.onDoneRequest();
        console.log(error.toString());
      });
    }
  }
}
