import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CodigoQRService } from 'src/app/services/codigoQR/codigo-qr.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-client-qr',
  templateUrl: './client-qr.component.html',
  styleUrls: ['./client-qr.component.css']
})
export class ClientQrComponent implements OnInit {
  loading = new LoadingEfectComponent();
  public catalog:any[];
  public modalQR:boolean = false;
  public nombre:string = "";

  public id = +this._route.snapshot.paramMap.get('id')
  identity = JSON.parse(localStorage.getItem('identity_user'));
  //------------Codigo QR-------------
  elementType = NgxQrcodeElementTypes.URL;
  errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "";
  nombreCode:string = "";

  constructor(
    private _service:CodigoQRService,
    private _serviceClient:ClientesService,
    private _route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit() {
    console.log(window.location.origin);
    this.getCodes();
    this.getCliente();
  }

  public getCliente(){
    this._serviceClient.getCliente(this.id).then(response=>{
      this.nombre = response.content[0].Nombre;
      console.log(response);
    });
  }

  public getCodes(){    
    this.loading.onLoadingRequest();
    this._service.getCodigosqr()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content.filter(x=>!x.Multiple);
        }
        else{
          Swal.fire("Atención","No hay datos que mostrar.","info");
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public preview(flag:boolean, id:number){
    if(id != 0){
      let catAct = this.catalog.find(cat => cat.CodigoQRID === id);
      this.nombreCode = catAct.Nombre;
      this.value = window.location.origin + '/#/info-qr/' + this.id + ',' + id;
    }
    this.modalQR = flag;
  }

  regresar(){
    this.router.navigate(["/sa-rm/admin-client/" + this.id + "/ft/" + this.id]);
  }
}
