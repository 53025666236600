import { Component, OnInit } from '@angular/core';
import { NoticiaService } from 'src/app/services/noticia/noticia.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { Router } from '@angular/router';
import { faFilePdf, faFileExcel, faFileWord, faFilePowerpoint, faFileImage } from '@fortawesome/free-solid-svg-icons';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit {
  faFilePdf = faFilePdf;
  faFileExcel = faFileExcel;
  faFileWord = faFileWord;
  faFileImage = faFileImage;
  faFilePowerpoint = faFilePowerpoint;
  loading = new LoadingEfectComponent();
  searchText;
  ordenador = 0;
  public catalog:any[];
  public catalogFiltered:any[] = [];
  public area:any[];
  public areaSelect:any[];
  labelArchivos = "Archivos";
  public archivos:any[];

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private sanitizer: DomSanitizer,
    private _service:NoticiaService,
    private _serviceUsuarios:UsuariosService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getRegistros();
    this.getAreas();
  }

  public getRegistros(){
    this.loading.onLoadingRequest();
    this._service.getNoticias()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.catalog = response.content.map((x) =>
          {
            x.Cuerpo = this.sanitizer.bypassSecurityTrustHtml(x.Cuerpo);
            return x
          });;
          this.catalogFiltered = this.catalog.filter(x => x.Marcar);
          this.catalogFiltered.push.apply(this.catalogFiltered,this.catalog.filter(x => !x.Marcar).sort((a, b) => a.INSERT_DATE.localeCompare(b.INSERT_DATE)));
          console.log(this.catalog);
        }else{
          this.catalog = [];
          Swal.fire("Sin registros","Aún no se han registrado noticias","info");      
        }
      }else if(response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getAreas(){    
    this.loading.onLoadingRequest();
    this._serviceUsuarios.getAreas()
    .then(response=>{
      this.loading.onDoneRequest();
      if(response.response.status == 200){
        if(response.content != null && response.content.length > 0){
          this.area = response.content;
          console.log(this.area)
        }
      }else if(response.response.status == 500){
        Swal.fire("Error",response.message,"error");
      }
    })
    .catch(error=>{
      this.loading.onDoneRequest();
      console.log(error.toString());
    });
  }

  public getArchivos(NoticiaID:number){
    let act = this.catalog.find(x=>x.NoticiaID == NoticiaID);
    this.labelArchivos = "Archivos de " + act.Titulo;
    this.archivos = act.Archivos;
  }

  public filtrar(){
    this.catalogFiltered = this.catalog.filter(x=>this.areaSelect.length == 0 || this.areaSelect.includes(x.AreaFK));
  }

  public ordenar(){
    if(this.ordenador == 0){
      this.catalogFiltered = this.catalogFiltered.sort((a, b) => a.INSERT_DATE.localeCompare(b.INSERT_DATE));
    }else{
      this.catalogFiltered = this.catalogFiltered.sort((a, b) => b.INSERT_DATE.localeCompare(a.INSERT_DATE));
    }
  }
}
