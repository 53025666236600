import { Component, Inject, OnInit  } from '@angular/core';
import * as $ from 'jquery';
import { 
  faTimes as fasTimes,
  faBars as fasBars,
  faCircle as fasCircle,
  faSignOutAlt as fasSignOut,
  faEnvelope as fasEnvelope,
  faChartArea as fasChartArea,
  faCog as fasCog,
  faTicketAlt,
  faNewspaper, faTruck,
  faSortAlphaUp as faSortAlphaUp
} from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/services/login/auth/auth.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-sidebar-cocl',
  templateUrl: './sidebar-cocl.component.html',
  styleUrls: ['./sidebar-cocl.component.css']
})
export class SidebarCoclComponent implements OnInit {
  fasTimes = fasTimes;
  fasEnvelope = fasEnvelope;
  fasBars = fasBars;
  fasCircle = fasCircle;
  fasSignOut = fasSignOut;
  faChartArea = fasChartArea;
  faNewspaper = faNewspaper;
  faTruck = faTruck;
  faCog = fasCog;
  faTicketAlt = faTicketAlt;
  faSortAlphaUp = faSortAlphaUp;

  private url:string;
  identity = JSON.parse(localStorage.getItem('identity_user'));
  private sesionCookies = this.identity.Sesion;
  private sesionBD: string = '';
  private objSesion: any = {};
  toggle:boolean = false;
  //Variables para Alertas
  itemsPendientes:number = 0;
  reactivosPendientes:number = 0;
  porCaducar:number = 0;
  caducados:number = 0;
  asistenciasNuevo:number = 0;
  asistenciasCurso:number = 0;
  alertaContrato:number = 0;
  alertaTarifas:number = 0;
  public Empresas:boolean = true;
  public Dashboard:boolean = true;
  public Configuraciones:boolean = true;

  //Modal Contacto
  public mdlReport : boolean = false;
  public obj:any = {};

  constructor(
    private _auth: AuthService,
    private _serviceUsuario: UsuariosService,
    private _serviceCliente: ClientesService,
    public router: Router,
    @Inject(DOCUMENT) document: any
  ) { 
    
  }

  ngOnInit() {
    console.log(this.identity);
    this.getAlertas();
    this.verifyClaims();
  }

  verifyClaims(){
    var claim1 = this.identity.permisoLists.find(x => x.nombrePermiso === 'Empresas');
    var claim2 = this.identity.permisoLists.find(x => x.nombrePermiso === 'Dashboard');
    var claim3 = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
    if(claim1 == undefined && claim1 == null){
      this.Empresas = false;
    }
    if(claim2 == undefined && claim2 == null){
      this.Dashboard = false;
    }
    if(claim3 == undefined && claim3 == null){
      this.Configuraciones = false;
    }
  }
    
  getAlertas(){
    this._serviceCliente.getAlertas(0)
    .then(response=>{
      if(response.status == 200){
        this.itemsPendientes = response.itemsPendientes;
        this.reactivosPendientes = response.reactivosPendientes;
        this.porCaducar = response.porCaducar;
        this.caducados = response.caducados;
        this.asistenciasNuevo = response.asistenciasNuevo;
        this.asistenciasCurso = response.asistenciasCurso;
        this.alertaContrato = response.alertaContrato;
        this.alertaTarifas = response.alertaTarifas;
        (async () => { 
          await this.delay(10000);
          // Do something after
          this.getAlertas();
        })();
      }else if (response.status == 500){      
        console.log(response.message);
      }else {
        console.log(response.message);
      }
    }).catch(error=>{ 
      console.log( error.toString());
    })
  }
    
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  
  openAlertas(tipo:number){
    this.router.navigate(['/sa-rm/admin/alertas/' + tipo]);
  }
  
    ngAfterViewInit(){  
      
    }
  
    ngAfterContentInit(){   
      
    }
    
    ngAfterContentChecked(){    
    }

    openmenu(){
      if(this.toggle){
        this.toggle = false;
      }else{
        this.toggle = true;
      }
    }
    
    closesidebar() {
      $(".page-wrapper").removeClass("toggled");
    }

    showsidebar() {
      $(".page-wrapper").addClass("toggled");
    }

    async getSesion(){      
      this._serviceUsuario.getSesion(this.identity.UsuarioId)
      .then(response =>{
         this.sesionBD = response.content;         
         if(this.sesionCookies == this.sesionBD){
            this.objSesion.usuarioid = this.identity.UsuarioId;
            this.objSesion.sesion = '';
            this._serviceUsuario.guardarSesion(this.objSesion);
         }
      });
    }

    async logout(){
      await this.getSesion();      

      this._auth.logOut();
      this.router.navigate(['/login-consultor']);
    }
    
    asistencia(){
      this.router.navigate(['/sa-rm/admin/asistencia']);
    }
    
    openAlertaContrato(){
      this.router.navigate(['/sa-rm/admin/alerta-contrato']);
    }

    active(id){
      window.location.href = '#/sa-rm/admin/' + id;
    }

    dashboard(){
      Swal.fire({
        title: '<h2>Dashboard</h2>',
        html: '<label style="color:white;">Selecciona el dashboard que desees visualizar</label>',
        showDenyButton: true,
        showCancelButton: true,
        background: '#15181e',
        confirmButtonText: 'Nacional',
        confirmButtonColor: '#dc3545',
        denyButtonText: `Extranjera`,
        denyButtonColor: '#ffc107',
        cancelButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          window.location.href = '#/sa-rm/admin/dashboard/Nacional';
        } else if (result.isDenied) {
          window.location.href = '#/sa-rm/admin/dashboard/Extranjera';
        }
      })
    }

    public openContact(open : boolean) : void {
      if(open){     
        $(".container").css('opacity',0.5);
        $("#sidebar-wrapper").css('opacity',0.5);
      }else{      
        $(".container").css('opacity',1); 
        $("#sidebar-wrapper").css('opacity',1);      
      }
      this.mdlReport = open;
    }

    enviarCorreo(){
      this._serviceUsuario.enviarCorreo(this.obj)
      .then(response=>{
        if(response.status == 200){
          Swal.fire('Mensaje enviado', 'Tu mensaje ha sido enviado de forma correcta', 'success');
        }else {
          Swal.fire('Error', response.message, 'error');
        }
      }).catch(error=>{ 
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
}
