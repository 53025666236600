<div id="site_wrapper">
    <div class="contenedor-principal">
      <div class="row mapa-sitio">
        <div class="col-sm-12 col-md-9">
            <h2>Códigos QR</h2> 
        </div>
      </div>
      <div class="row principal">
          <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
              
          </div>
          <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2">
              <button class='btn-layout btn-fluid float-right btnTableUp' (click)='openModalQR(true,0)' type='button' style='margin-left:10px;'>
                  CREAR NUEVO
              </button>
          </div>
      </div>
      <div class="row tabla-catalogo">  
          <div class="col-12">
              <table class="table table-responsive"> 
                  <thead>
                  <tr>
                      <th width="40%">NOMBRE</th>
                      <th width="10%">DESCRIPCIÓN</th>
                      <th width="10%">PASSWORD</th>
                      <th width="20%">FECHA DE CREACIÓN</th>
                      <th class="no-sort"></th>
                      <th class="no-sort"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let group of codes">
                      <td>{{group.Nombre}}</td>
                      <td>{{group.Descripcion}}</td>
                      <td>{{group.Password}}</td>
                      <td>{{group.INSERT_DATE | date:'dd/MM/yyyy'}}</td>
                      <td>
                        <img class="btn-accion" src="../../../assets/IMG/edit-background-gray.png" (click)="openModalQR(true,group.CodigoQRID)" width="40" height="30" title="Editar">
                      </td>
                      <td>
                        <button class="btn btn-Eliminar btn-ch btnTableUp" (click)="delete(group.CodigoQRID)">Eliminar</button>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>             
      </div>
    </div>
</div>
  
<div class="modal fade" id="edicionModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': modalQR ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openModalQR(false,0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form #editForm="ngForm" (ngSubmit)="update()" autocomplete="off" appIdentityRevealed method="POST" enctype="multipart/form-data">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="Nombre">NOMBRE</label>
                  <input type="text" class="form-control" #Nombre="ngModel" name="Nombre" [(ngModel)]="obj.Nombre" placeholder="Ingresa texto"  required/>
                  <div *ngIf="Nombre.invalid" class="lbl-error">
                      <span *ngIf="Nombre.errors.required">
                          El nombre del código es requerido
                      </span>
                  </div>
                </div>
                <div class="form-group">
                  <label for="Descripcion">DESCRIPCIÓN</label>
                  <input type="text" class="form-control" #Descripcion="ngModel" name="Descripcion" [(ngModel)]="obj.Descripcion" placeholder="Ingresa texto"/>
                </div>
                <div class="form-group">
                  <label for="Password">PASSWORD</label>
                  <input type="text" class="form-control" #Password="ngModel" name="Password" [(ngModel)]="obj.Password" placeholder="Ingresa texto" required/>
                  <div *ngIf="Password.invalid" class="lbl-error">
                      <span *ngIf="Password.errors.required">
                          El password es requerido
                      </span>
                  </div>
                </div>
                <div class="form-group" [hidden]="item.length > 1">
                    <label class="switch">
                        <input #Multiple="ngModel" name="Multiple" [(ngModel)]="obj.Multiple" type="checkbox" >
                        <span class="slider round"></span>
                    </label>
                    &nbsp;
                    <label class="lblSwitch">Item Múltiple</label>
                </div>
                <h5 class="w-100">INFORMACIÓN A MOSTRAR 
                    <a class="lbl-noActive ml-2" style="cursor: pointer;" [hidden]="obj.Multiple" (click)="agregarItem()">+Agregar</a>
                </h5>
              </div>
              <div class="row m-0" *ngFor="let numSPN of item; index as i;">
                <div class="col-5">
                  <div class="form-group">
                      <label for="Item{{i}}">Item</label>
                      <ng-select id="Item{{i}}" 
                              #Item[i]="ngModel" 
                              (change)="getReactivos(i)" 
                              name="Item{{i}}" 
                              placeholder="Seleccionar..."
                              [(ngModel)]="itemIndex[i].ItemID">
                          <ng-option [value]="item.ItemID" *ngFor="let item of catalog">
                              {{item.Nombre}}
                          </ng-option>
                      </ng-select>
                  </div>
                </div>
                <div class="col-5">
                  <div class="form-group">
                      <label for="React{{i}}">Reactivos</label>
                      <ng-select id="React{{i}}" 
                              #React[i]="ngModel" 
                              name="React{{i}}" 
                              placeholder="Seleccionar..."                                
                              multiple="true"
                              [(ngModel)]="itemIndex[i].Reactivos">
                          <ng-option [value]="item.ReactivoID" *ngFor="let item of itemIndex[i].React">
                              {{item.Nombre}}
                          </ng-option>
                      </ng-select>
                  </div>
                </div>
                <div class="col-2 pt-1">
                    <button class='btn-Eliminar btn-ch btnTableUp ml-2' type='button' style='margin-top: 30px !important;' (click)="deleteItem(i)">
                        ELIMINAR
                    </button>
                </div>
              </div>
            </div>
            <div class="div-btns row pt-3">
              <div class="col-6">
                <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="openModalQR(false,0)" data-dismiss="modal" type="button">CANCELAR</button>            
              </div>
              <div class="col-6">    
                <button class="btn btn-fluid btn-layout btnTableUp" [disabled]="editForm.invalid">GUARDAR</button>   
              </div> 
            </div>
          </form>               
        </div>       
      </div>
    </div>
</div>

<app-loading-efect></app-loading-efect>