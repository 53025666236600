import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoadingEfectComponent } from 'src/app/Layout/loading-efect/loading-efect.component';
import { ClientesService } from 'src/app/services/clientes/clientes.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})

export class UsuariosComponent implements OnInit {
  loading = new LoadingEfectComponent();
  searchText;
  public obj:any = {};
  public bdy:any = {};
  
  public users:any[];
  public catalog:any = {};
  public empresasID:any[];

  identity = JSON.parse(localStorage.getItem('identity_user'));

  constructor(
    private _service:UsuariosService,
    private _serviceEmp:ClientesService,
    public router: Router
    ) { }
  
  public mdlCreacion : boolean = false;
  public mdlEdicion : boolean = false;

ngOnInit() {
  this.verifyClaims();
  this.getRegistros();
  this.getRoles();
  this.getAreas();
  this.getEmpresas();
  this.loading.onDoneRequest();
}

verifyClaims(){
  var claim = this.identity.permisoLists.find(x => x.nombrePermiso === 'Configuraciones');
  if(claim == undefined && claim == null){
    this.router.navigate(['/sa-rm/admin']);
  }
}

public getRegistros(){    
  this.loading.onLoadingRequest();
  this._service.getRegistros()
  .then(response=>{
    this.loading.onDoneRequest();
    if(response.status == 200){
      if(response.content != null && response.content.length > 0){
        this.users = response.content;
      }else{
        Swal.fire("Sin registros","Aún no se han registrado usuarios","info");      
      }
    }else if(response.status == 500){
      Swal.fire("Error",response.message,"error");
    }
  })
  .catch(error=>{
    this.loading.onDoneRequest();
    console.log(error.toString());
  });
}

public getRoles(){    
  this._service.getRoles()
  .then(response=>{
    if(response.response.status == 200){
      if(response.content != null && response.content.length > 0){
        this.catalog.Roles = response.content;
      }
    }else if(response.response.status == 500){
      Swal.fire("Error",response.message,"error");
    }
  })
  .catch(error=>{
    console.log(error.toString());
  });
}

public getAreas(){    
  this._service.getAreas()
  .then(response=>{
    if(response.response.status == 200){
      if(response.content != null && response.content.length > 0){
        this.catalog.Areas = response.content;
      }
    }else if(response.response.status == 500){
      Swal.fire("Error",response.message,"error");
    }
  })
  .catch(error=>{
    console.log(error.toString());
  });
}

public openEdicion(open : boolean, id: number) : void {
  this.clearInput();
  let userAct = this.users.find(usr => usr.UsuarioID === id);
  console.log(userAct)
  if(open){     
    $(".container").css('opacity',0.5);
    $("#sidebar-wrapper").css('opacity',0.5);
    $(".modal-title").html("EDITAR USUARIO");
    this.obj.idUser = id;
    this.obj.Nombre = userAct.Nombre;
    this.obj.Correo = userAct.Correo;
    this.obj.AreaId = userAct.Area;
    this.obj.RolId = userAct.Rol;
    this.obj.ClaimDashboardGeneral = userAct.ClaimDashboardGeneral;
    this.obj.ClaimVerEliminados = userAct.ClaimVerEliminados;
    this.obj.ClaimEliminarItem = userAct.ClaimEliminarItem;
    this.obj.ClaimCargaEmpresa = userAct.ClaimCargaEmpresa;
    this.empresasID = !userAct.ClaimCargaEmpresa || userAct.ClaimCargaEmpresa == '' ? [] : userAct.ClaimCargaEmpresa.split(",").map( Number );
  }else{      
    $(".container").css('opacity',1); 
    $("#sidebar-wrapper").css('opacity',1);      
  }
  this.mdlEdicion = open;
}

public open(open : boolean) : void {
  if(open){     
    $(".container").css('opacity',0.5);
    $("#sidebar-wrapper").css('opacity',0.5);
    $(".modal-title").html("CREAR USUARIO");
    this.clearInput();
  }else{      
    $(".container").css('opacity',1); 
    $("#sidebar-wrapper").css('opacity',1);      
  }
  this.mdlCreacion = open;
}

update(){
  this.obj.ClaimCargaEmpresa = this.empresasID ? this.empresasID.toString() : "";
  this.loading.onLoadingRequest();
  this._service.update(this.obj)
  .then(response=>{
    this.loading.onDoneRequest();
    if(response.status == 200){
      Swal.fire('Éxito', response.message, 'success');
      this.openEdicion(false, 0);
      this.getRegistros();
    }else if (response.status == 500){      
      Swal.fire('Error', response.message, 'error');
    }else {
      Swal.fire("Atención",response.message,"info");
    }
  }).catch(error=>{ 
    this.loading.onDoneRequest();     
    Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
  })
}

create(){  
  this.obj.ClaimCargaEmpresa = this.empresasID ? this.empresasID.toString() : "";
  this.loading.onLoadingRequest();
  this._service.update(this.obj)
  .then(response =>{
    this.loading.onDoneRequest();
    if(response.status == 200){
      Swal.fire('Éxito', response.message, 'success');
      this.open(false)
      this.getRegistros();
    }else if (response.status == 500){      
      Swal.fire('Error', response.message, 'error');
    }else {
      Swal.fire("Atención",response.message,"info");
    }
  })
  .catch(error => {
    this.loading.onDoneRequest();
    Swal.fire("Error de conexión con el servidor",error.toString(),"error");
  })

}

delete(id: number){ 
  Swal.fire({
    title: 'Atención',
    text: "¿Estas seguro que deseas eliminar el registro?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      this.loading.onLoadingRequest();
      this._service.delete(id)
      .then(response=>{
        this.loading.onDoneRequest();
        if(response.status == 200){
          Swal.fire('Éxito', response.message, 'success');
          this.openEdicion(false, 0);        
        
          this.getRegistros();
        }else{
          Swal.fire('Error', response.message, 'error');
        }
        
      }).catch(error=>{   
        this.loading.onDoneRequest();   
        Swal.fire('Error de conexión con el servidor', error.toString(), 'error');
      })
    }
  })
}

clearInput(){
  this.obj.idUser = 0;
  this.obj.Nombre = "";
  this.obj.Correo = "";
  this.obj.AreaId = "";
  this.obj.RolId = "";
  this.obj.ClaimDashboardGeneral = false;
  this.obj.ClaimVerEliminados = false;
  this.obj.ClaimEliminarItem = false;
  this.obj.ClaimCargaEmpresa = "";
  this.empresasID = [];
};

public getEmpresas(){
  this.loading.onLoadingRequest();
  this._serviceEmp.getCliente(0)
  .then(response=>{
    this.loading.onDoneRequest();
    if(response.status == 200){
      if(response.content != null && response.content.length > 0){
        this.catalog.empresas = response.content;
        console.log(this.catalog.empresas);
      }
    }else if(response.status == 500){
      Swal.fire("Error",response.message,"error");
    }
  })
  .catch(error=>{
    this.loading.onDoneRequest();
    console.log(error.toString());
  });
}
}