<div id="site_wrapper">
    <div class="contenedor-principal">
      <div class="row mapa-sitio">
        <div class="col-sm-12 col-md-9">
            <div>
                <a class="lbl-noActive" href="/#/sa-rm/admin/config">Configuraciones > </a>
                <a href="/#/sa-rm/admin/config" class="lbl-noActive">&nbsp;ITEMS > </a>
                <a href="/#/sa-rm/items" class="lbl-noActive">&nbsp;LISTA > </a>
                <a (click)="goBack()" class="lbl-noActive" style="cursor: pointer;">&nbsp;ITEM {{item.Nombre}} > </a>
                <label class="lbl-active">&nbsp;Reactivos</label>
            </div>
            <h2>Reactivos {{item.Nombre}}</h2> 
        </div>
        <div class="col-sm-12">
            <a (click)="goBack()" class="float-right link-regresar" style="cursor: pointer;"><img src="../../../assets/IMG/arrow_back.png" /></a>
        </div>
      </div>
      <div class="wizard"></div>
      <div class="row principal">
          <div class="col-sm-7 col-md-8 col-lg-9 col-xl-10">
              <input type="text" [(ngModel)]="searchText" class="buscador form-control" placeholder="Buscador">
          </div>
          <div class="col-sm-5 col-md-4 col-lg-3 col-xl-2">
              <button class='btn-layout btn-fluid float-right btnTableUp' (click)='open(true, 0)' type='button' style='margin-left:10px;'>
                  CREAR NUEVO
              </button>
          </div>
      </div>
      <div class="row tabla-catalogo">  
          <div class="col-12">
              <table class="table table-responsive"> 
                  <thead>
                  <tr>
                      <th width="5%">ÓRDEN</th>
                      <th width="25%">REACTIVO</th>
                      <th width="10%">TIPO CAMPO</th>
                      <th width="10%">TIPO PERSONA</th>
                      <th width="10%">PERIODICIDAD</th>
                      <th width="5%">VENCIMIENTO</th>
                      <th width="5%">MANDATORIO</th>
                      <th width="5%">OPCIONAL</th>
                      <th width="5%">MÚLTIPLE</th>
                      <th width="5%">CALIFICACIÓN</th>
                      <th width="15%">FECHA DE CREACIÓN</th>
                      <th class="no-sort"></th>
                      <th class="no-sort"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let group of catalog | reactivo: searchText; ">
                      <td>{{group.Orden}}</td>
                      <td>{{group.Nombre}}</td>
                      <td>{{group.NombreTipo}}</td>
                      <td>{{group.NombreTipoPersona}}</td>
                      <td>{{group.PeriodicidadVal}}</td>
                      <td>{{group.VencimientoVal}}</td>
                      <td>{{group.Mandatorio}}</td>
                      <td>{{group.OpcionalVal}}</td>
                      <td>{{group.MultipleVal}}</td>
                      <td>{{group.Calificacion}}</td>
                      <td>{{group.FechaCreacion | date:'dd/MM/yyyy'}}</td>
                      <td>
                        <img class="btn-accion" src="../../../assets/IMG/edit-background-gray.png" (click)="open(true, group.ReactivoID)" width="40" height="30" title="Editar">
                      </td>
                      <td>
                        <button class="btn btn-Eliminar btn-ch btnTableUp" (click)="delete(group.ReactivoID)">Eliminar</button>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>             
      </div>

      <div class="botones-wizard3 row">
        <div class="col-12">
            <button class="btn-layout pr-2 pl-2 float-right btnTableUp" (click)="saveItem()">TERMINAR</button>   
        </div>
      </div>
    </div>
</div>


<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-hidden="true" [ngStyle]="{'display': mdl ? 'block' : 'none', 'opacity': 1}"> 
    <div class="modal-dialog modal-lg modal-dialog-centered" role="dialog" >
      <div class="modal-content">
        <div class="modal-header mdl-hdr text-center">
          <h5 class="modal-title w-100"></h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="open(false, 0)">
            <span aria-hidden="true" class="mdl-close" >&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form #createForm="ngForm" (ngSubmit)="update()" autocomplete="off" appIdentityRevealed>
                <div class="form-group">
                    <label for="Orden">ÓRDEN</label>
                    <input type="number" class="form-control" #Orden="ngModel" name="Orden" [(ngModel)]="obj.Orden" placeholder="Ingresa texto"  required pattern="[^0].*"/>
                    <div *ngIf="Orden.invalid"
                        class="lbl-error">
                        <span *ngIf="Orden.errors.required || Orden.errors.pattern">
                            El nombre es requerido
                        </span>            
                    </div>
                </div>
                <div class="form-group">
                    <label for="Nombre">NOMBRE</label>
                    <input type="text" class="form-control" #Nombre="ngModel" name="Nombre" [(ngModel)]="obj.Nombre" placeholder="Ingresa texto"  required />
                    <div *ngIf="Nombre.invalid"
                        class="lbl-error">
                        <span *ngIf="Nombre.errors.required">
                            El nombre es requerido
                        </span>            
                    </div>
                </div>
                <div class="form-group">
                    <label for="Descripcion">DESCRIPCIÓN</label>
                    <textarea placeholder="Ingresa texto" #Descripcion="ngModel" name="Descripcion" [(ngModel)]="obj.Descripcion" class="form-control" id="Descripcion" style="resize: none;" rows="2" required></textarea>
                    <div *ngIf="Descripcion.invalid"
                      class="lbl-error">
                      <span *ngIf="Descripcion.errors.required">
                          La descripción es requerida
                      </span>            
                    </div>
                </div>
                <div class="form-group">
                    <label for="">TIPO CAMPO</label>
                    <select name="" id="TipoFK" #TipoFK="ngModel" name="TipoFK" [(ngModel)]="obj.TipoFK" class="form-control" pattern="[^0].*">
                        <option value="0">Seleccionar...</option>
                        <option *ngFor="let item of catalogs.TipoCampo" [value]="item.ID">{{item.Nombre}}</option>
                    </select>
                    <div *ngIf="TipoFK.invalid"
                        class="lbl-error">
                        <span *ngIf="TipoFK.errors.required || TipoFK.errors.pattern">
                            El tipo de campo es requerido
                        </span>            
                    </div>
                </div>
                <div class="form-group" [hidden]="obj.TipoFK != 8">
                    <label for="">TIPO CATÁLOGO</label>
                    <select name="" id="TipoCatalogoID" #TipoCatalogoID="ngModel" name="TipoCatalogoID" [(ngModel)]="obj.TipoCatalogoID" class="form-control">
                        <option value="0">Seleccionar...</option>
                        <option *ngFor="let item of catalogs.TipoCatalog" [value]="item.ID">{{item.Nombre}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">TIPO PERSONA</label>
                    <select name="" id="TipoPersona" #TipoPersona="ngModel" name="TipoPersona" [(ngModel)]="obj.TipoPersona" class="form-control" pattern="[^0].*">
                        <option value="0" disabled>Seleccionar...</option>
                        <option value="1">Moral</option>
                        <option value="2">Física</option>
                        <option value="3">Ambas</option>
                    </select>
                    <div *ngIf="TipoPersona.invalid"
                        class="lbl-error">
                        <span *ngIf="TipoPersona.errors.required || TipoPersona.errors.pattern">
                            El tipo de persona es requerido
                        </span>            
                    </div>
                </div>
                <div class="form-group">
                    <label for="Calificacion">CALIFICACIÓN</label>
                    <input type="number" class="form-control" #Calificacion="ngModel" name="Calificacion" [(ngModel)]="obj.Calificacion" placeholder="Ingresa texto"  required/>
                    <div *ngIf="Calificacion.invalid"
                        class="lbl-error">
                        <span *ngIf="Calificacion.errors.required">
                            La calificación es requerida
                        </span>            
                    </div>
                </div>
                <div class="form-group">
                    <label for="Calificacion">MANDATORIO</label>
                    <select name="" id="Mandatorio" #Mandatorio="ngModel" name="Mandatorio" [(ngModel)]="obj.Mandatorio" class="form-control" pattern="[^0].*">
                        <option value="0" disabled>Seleccionar...</option>
                        <option value="Para Firma de Contrato">Para Firma de Contrato</option>
                        <option value="Para Visita de Certificación">Para Visita de Certificación</option>
                        <option value="Para Iniciar Operaciones">Para Iniciar Operaciones</option>
                        <option value="Por Evento">Por Evento</option>
                        <option value="Deseable">Deseable</option>
                    </select>
                    <div *ngIf="Mandatorio.invalid"
                        class="lbl-error">
                        <span *ngIf="Mandatorio.errors.required || Mandatorio.errors.pattern">
                            El tipo de mandatorio es requerido
                        </span>            
                    </div>
                </div>
                <div class="form-group">
                    <label for="Calificacion">TIPO LINEA</label>
                    <ng-select id="TipoLinea" 
                            #TipoLinea="ngModel" 
                            name="TipoLinea" 
                            placeholder="Seleccionar..."                                
                            multiple="true"
                            [(ngModel)]="tipoLinea" required>
                        <ng-option [value]="item.ID" *ngFor="let item of catalogs.TipoLinea">
                            {{item.Nombre}}
                        </ng-option>
                    </ng-select>
                    <div *ngIf="TipoLinea.invalid"
                        class="lbl-error">
                        <span *ngIf="TipoLinea.errors.required">
                            El tipo de linea es requerido
                        </span>            
                    </div>
                </div>
                <div class="form-group">
                    <label for="NacionalExtranjera">NACIONAL/EXTRANJERA</label>
                    <select name="" id="NacionalExtranjera" #NacionalExtranjera="ngModel" name="NacionalExtranjera" [(ngModel)]="obj.NacionalExtranjera" class="form-control" pattern="[^0].*" required>
                        <option value="0" disabled>Seleccionar...</option>
                        <option value="Nacional">Nacional</option>
                        <option value="Extranjera">Extranjera</option>
                        <option value="Ambas">Ambas</option>
                    </select>
                    <div *ngIf="NacionalExtranjera.invalid"
                        class="lbl-error">
                        <span *ngIf="NacionalExtranjera.errors.required || NacionalExtranjera.errors.pattern">
                            El campo es requerido
                        </span>            
                    </div>
                </div>
                <div class="switch-form-base">
                     <label class="switch">
                         <input [(ngModel)]="Periodicidadswt" type="checkbox" id="Periodicidadswt" name="Periodicidadswt" (change)="setPeriodicidad()">
                         <span class="slider round"></span>
                     </label>
                     &nbsp;
                     <label class="lblSwitch">PERIODICIDAD</label>
                </div>
                <div class="form-group" *ngIf="Periodicidadswt">
                    <input type="number" class="form-control" #Periodicidad="ngModel" name="Periodicidad" [(ngModel)]="obj.Periodicidad" placeholder="Ingresa texto"  required/>
                    <div *ngIf="Periodicidad.invalid"
                        class="lbl-error">
                        <span *ngIf="Periodicidad.errors.required">
                            La periodicidad es requerida
                        </span>            
                    </div>
                </div>
                <div class="switch-form-base">
                     <label class="switch">
                         <input [(ngModel)]="obj.Vencimiento" type="checkbox" id="Vencimiento" name="Vencimiento">
                         <span class="slider round"></span>
                     </label>
                     &nbsp;
                     <label class="lblSwitch">VENCIMIENTO</label>
                </div>
                <div class="switch-form-base">
                     <label class="switch">
                         <input [(ngModel)]="obj.Opcional" type="checkbox" id="Opcional" name="Opcional">
                         <span class="slider round"></span>
                     </label>
                     &nbsp;
                     <label class="lblSwitch">OPCIONAL</label>
                </div>
                <div class="switch-form-base">
                     <label class="switch">
                         <input [(ngModel)]="obj.Multiple" type="checkbox" id="Multiple" name="Multiple">
                         <span class="slider round"></span>
                     </label>
                     &nbsp;
                     <label class="lblSwitch">MÚLTIPLE</label>
                </div>
                <div class="form-group">
                    <label for="agrupado">Agrupar</label>
                    <ng-select
                        placeholder="Seleccionar..."                                
                        multiple="true"
                        #agrupado="ngModel"
                        name="agrupado"
                        [(ngModel)]="agrupados"
                        id="agrupado">
                        <ng-option [value]="item.ReactivoID" *ngFor="let item of catalog">
                            {{item.Nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="form-group">
                    <label for="accessV">ACCESOS VISTA</label>
                    <ng-select
                        placeholder="Seleccionar..."                                
                        multiple="true"
                        #accessV="ngModel"
                        name="accessV"
                        [(ngModel)]="obj.accessV"
                        id="accessV">
                        <ng-option [value]="item.ID" *ngFor="let item of catalogs.Roles">
                            {{item.Nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="form-group">
                    <label for="accessC">ACCESOS CARGA</label>
                    <ng-select
                        placeholder="Seleccionar..."                                
                        multiple="true"
                        #accessC="ngModel"
                        name="accessC"
                        [(ngModel)]="obj.accessC"
                        id="accessC">
                        <ng-option [value]="item.ID" *ngFor="let item of catalogs.Roles">
                            {{item.Nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="form-group">
                    <label for="accessW">ACCESOS VALIDACIÓN</label>
                    <ng-select
                        placeholder="Seleccionar..."                                
                        multiple="true"
                        #accessW="ngModel"
                        name="accessW"
                        [(ngModel)]="obj.accessW"
                        id="accessW">
                        <ng-option [value]="item.ID" *ngFor="let item of catalogs.Roles">
                            {{item.Nombre}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="div-btns row pt-3">   
                    <div class="col-6">
                      <button class="btn btn-fluid btn-Eliminar btnTableUp" (click)="open(false, 0)" data-dismiss="modal" type="button">CANCELAR</button>            
                    </div>
                    <div class="col-6">     
                      <button class="btn btn-fluid btn-layout btnTableUp" [disabled]="createForm.invalid">GUARDAR</button>    
                    </div>
                </div>
            </form>            
        </div>       
      </div>
    </div>
  </div>

<app-loading-efect></app-loading-efect>